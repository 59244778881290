/* eslint-disable camelcase */

function resizeParams(shape, { xFactor, yFactor }) {
  if (!shape.params || !Array.isArray(shape.params)) return;

  if (shape.type === 'polygon' || shape.type === 'unrestricted_polygon' || shape.type === 'polyline') {
    shape.params = shape.params.map((param) => [param[0] * xFactor, param[1] * yFactor]);
  } else if (shape.type === 'ellipse') {
    shape.params = shape.params.map((param, index) => {
      if (index > 3) return param;
      return param * (index % 2 ? yFactor : xFactor);
    });
  }
}

function resizeMaskShapes(maskShapes, resizeFactor) {
  if (!maskShapes) return;

  if (maskShapes.mask) {
    resizeParams(maskShapes.mask, resizeFactor);
  }

  if (maskShapes.shapes && Array.isArray(maskShapes.shapes)) {
    maskShapes.shapes.forEach((shape) => resizeParams(shape, resizeFactor));
  }
}

function resizeVisualizationData(visualizationData, { xFactor, yFactor }) {
  if (visualizationData.x) {
    visualizationData.x = visualizationData.x.map((point) => point * xFactor);
  }
  if (visualizationData.y) {
    visualizationData.y = visualizationData.y.map((point) => point * yFactor);
  }
}

function resizeResult(result, { xFactor, yFactor }) {
  if (result.AOI_radius_o) {
    result.AOI_radius_o *= (xFactor + yFactor) / 2;
  }
  if (result.AOI_x_o) {
    result.AOI_x_o *= xFactor;
  }
  if (result.AOI_y_o) {
    result.AOI_y_o *= yFactor;
  }
  if (result.mask_shapes) {
    resizeMaskShapes(result.mask_shapes, { xFactor, yFactor });
  }
  if (result.visualization_data) {
    result.visualization_data.forEach((data) => resizeVisualizationData(data, { xFactor, yFactor }));
  }
  if (result.measurement_locations) {
    result.measurement_locations.forEach((location) => resizeResult(location, { xFactor, yFactor }));
  }
}

export function resizeResults(results, resizeFactor) {
  results.forEach((result) => resizeResult(result, resizeFactor));
}

export function resizeFaceArea(faceArea, { xFactor, yFactor }) {
  if (faceArea.bounding_box) {
    faceArea.bounding_box = faceArea.bounding_box.map((point, index) => point * (index % 2 ? yFactor : xFactor));
  }
}
