import { asyncForEach } from './generalFunctions';
import { draw } from './draw';

// lookup tables for marching directions
const contourDx = [1, 0, 1, 1, -1, 0, -1, 1, 0, 0, 0, 0, -1, 0, -1, NaN];
const contourDy = [0, -1, 0, 0, 0, -1, 0, 0, 1, -1, 1, 1, 0, -1, 0, NaN];

const contourStart = ({ data, width }) => {
  let x = 0;
  let y = 0;
  // search for a starting point; begin at origin
  // and proceed along outward-expanding diagonals
  for (let i = 0; i < data.length; i++) {
    if (defineNonTransparent({ data, width, x, y })) {
      return [x, y];
    }
    if (x === 0) {
      x = y + 1;
      y = 0;
    } else {
      x -= 1;
      y += 1;
    }
  }

  return undefined;
};

// This is used by the marching ants algorithm
// to determine the outline of the non-transparent
// pixels on the image
const defineNonTransparent = ({ data, width, x, y }) => {
  const a = data[(y * width + x) * 4 + 3];
  return a > 20;
};

const contour = ({ data, width }) => {
  const startingPoint = contourStart({ data, width });
  let contourPolygon = [];
  let x = startingPoint[0];
  let y = startingPoint[1];
  let nextXDirection = 0;
  let nextYDirection = 0;
  let previousXDirection = NaN;
  let previousYDirection = NaN;
  let i = 0;

  do {
    // determine marching squares index
    i = 0;
    if (defineNonTransparent({ data, width, x: x - 1, y: y - 1 })) i += 1;
    if (defineNonTransparent({ data, width, x, y: y - 1 })) i += 2;
    if (defineNonTransparent({ data, width, x: x - 1, y })) i += 4;
    if (defineNonTransparent({ data, width, x, y })) i += 8;

    // determine next direction
    if (i === 6) {
      nextXDirection = previousYDirection === -1 ? -1 : 1;
      nextYDirection = 0;
    } else if (i === 9) {
      nextXDirection = 0;
      nextYDirection = previousXDirection === 1 ? -1 : 1;
    } else {
      nextXDirection = contourDx[i];
      nextYDirection = contourDy[i];
    }

    // update contour polygon
    if (nextXDirection !== previousXDirection && nextYDirection !== previousYDirection) {
      contourPolygon.push([x, y]);
      previousXDirection = nextXDirection;
      previousYDirection = nextYDirection;
    }

    x += nextXDirection;
    y += nextYDirection;
  } while (startingPoint[0] !== x || startingPoint[1] !== y);

  return contourPolygon;
};

const getContour = ({ canvas }) => {
  const context = canvas.getContext('2d');
  const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
  return contour({ data: imageData.data, width: canvas.width });
};

function createNewCanvas({ width, height }) {
  let canvas = document.createElement('canvas');
  canvas.setAttribute('id', 'tmpMaskShapeCanvas');
  canvas.setAttribute('width', width);
  canvas.setAttribute('height', height);
  canvas.style.position = 'absolute';
  canvas.style.top = '0px';
  canvas.style.left = '0px';
  canvas.style.transformOrigin = 'top left';
  const ctx = canvas.getContext('2d');
  ctx.clearRect(0, 0, width, height);
  return canvas;
}

let cachedMasks = {};
let tmpCanvas = null;

export const clearCachedMasks = () => {
  cachedMasks = {};
};

export const getMasksShape = async ({ masks, width, height }) => {
  if (!tmpCanvas) tmpCanvas = createNewCanvas({ width, height });
  const processedMasks = [];
  await asyncForEach(masks, async (mask) => {
    if (cachedMasks[`${mask.section}-${mask.name}`]) {
      processedMasks.push(cachedMasks[`${mask.section}-${mask.name}`]);
    } else {
      const ctx = tmpCanvas.getContext('2d');
      ctx.clearRect(0, 0, width, height);
      await draw(tmpCanvas, mask, null, '#000', false, null);
      const contourPoints = getContour({ canvas: tmpCanvas });
      if (!contourPoints || contourPoints.length <= 0) return;
      const shape = { ...mask, contour: contourPoints };
      cachedMasks[`${mask.section}-${mask.name}`] = shape;
      processedMasks.push(shape);
    }
  });
  return processedMasks;
};

export default getContour;
