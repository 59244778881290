import rfdc from 'rfdc';
import { addToCart } from '../services/addToCart';
import * as services from '../services/recommendedProducts';

class RevievePR {
  /**
   * RevievePR class.
   * Revieve Product Recommendations module
   * @constructor
   * @param {Object} sdk - instance of RevieveSDK
   */
  constructor(sdk) {
    if (!sdk) {
      console.error('RevieveCV: Constructor needs an SDK instance');
      Object.create(null);
    }
    this._sdk = sdk;
    this._state = {};
  }

  /**
   * Method to get recommended products based in the provided query parameters.
   *
   * @returns {Promise}
   * @param {Object} [query] - Recommender query parameters.
   */
  queryRecommendedProducts(query) {
    return new Promise((resolve, reject) => {
      let userId = this._sdk.getUserId();
      if (!userId) {
        let message = 'You must set UserId to get recommended products';
        console.error(message);
        reject(new Error(message));
      }
      this._sdk.analytics.sendEvent('RevievePR.getRecommendedProducts');
      services
        .getRecommendedProducts(
          this._sdk.getPartnerId(),
          this._sdk.getUserId(),
          query,
          this._sdk.isTesting(),
          this._sdk.getEnvironment(),
        )
        .then((response) => {
          if (!response) {
            let message = 'Error retrieving recommended products from server';
            console.error(message);
            reject(new Error(message));
          }
          if (response.result.products) {
            this._state.recommendedProducts = response.result.products;
          }
          if (response.result.variations) {
            this._state.recommendedProducts = response.result.variations.map((variation) => ({
              products: variation.products,
              variantName: variation.variantName,
            }));
          }
          this._state.historyId = response.result.history_id;
          this._state.routines = response.result.routines;
          resolve(this._state.recommendedProducts);
        })
        .catch((error) => {
          if (error) {
            console.error(error);
          }
          reject(error);
        });
    });
  }

  /**
   * Method to get recommended products based in the parameters set in SDK state. If worstMetricsOptions
   * is provided, it will filter the worst metrics to get the recommended products.
   *
   * @param {Object} [worstMetricsOptions] - Object with minThreshold and limit to filter the worst metrics
   * @param {number} worstMetricsOptions.minThreshold - Minimum threshold for the worst metrics.
   * @param {number} worstMetricsOptions.limit - Limit of metrics to consider.
   * @returns {Promise}
   */
  getRecommendedProducts(worstMetricsOptions) {
    const options = this._sdk.getConfiguration(worstMetricsOptions);

    return this.queryRecommendedProducts(options);
  }

  /**
   * Method to get a list of products and its score based in the parameters setted in SDK state.
   *
   * @param {string[]} [productIds] - List of product ids.
   * @returns {Promise}
   */
  getProductsWithScores(productIds) {
    return new Promise((resolve, reject) => {
      services
        .getProductsWithScores(
          this._sdk.getPartnerId(),
          this._sdk.getUserId(),
          this._sdk.getConfiguration(),
          this._sdk.isTesting(),
          this._sdk.getEnvironment(),
          productIds,
        )
        .then((response) => {
          if (!response) {
            let message = 'Error retrieving recommended products from server';
            console.error(message);
            reject(new Error(message));
            return;
          }

          if (response.result.products) {
            resolve(response.result.products);
          }
        })
        .catch((error) => {
          if (error) {
            console.error(error);
          }
          reject(error);
        });
    });
  }

  /**
   * Method to get a list of alternative products and its score of a product based in the parameters setted in SDK state.
   *
   * @param {string} [productId] - Product from which we want to obtain alternative products.
   * @param {number} [max] - number of alternatives products we want to obtain. If not provided is '0' and gets all the possible alternatives.
   * @param {string[]} categories - List of routine categories associated with the product.
   * @param {string[]} excludeProductIds - List of product ids to exclude from the alternatives.
   * @returns {Promise}
   */
  getAlternativeProducts(productId, max, categories, excludeProductIds) {
    return new Promise((resolve, reject) => {
      services
        .getAlternativeProducts(
          this._sdk.getPartnerId(),
          this._sdk.getUserId(),
          this._sdk.getConfiguration(),
          this._sdk.isTesting(),
          this._sdk.getEnvironment(),
          productId,
          max,
          categories,
          excludeProductIds,
        )
        .then((response) => {
          // eslint-disable-next-line camelcase
          if (!response?.result?.products || !response?.result?.history_id) {
            let message = 'Error retrieving recommended products from server';
            console.error(message);
            reject(new Error(message));
            return;
          }
          resolve({ products: response.result.products, historyId: response.result.history_id });
        })
        .catch((error) => {
          if (error) {
            console.error(error);
          }
          reject(error);
        });
    });
  }

  /**
   * Method to get individual product information
   *
   * @param {String} id product identifier
   *
   * @param {String} gtin product gtin identifier
   *
   * @returns {Promise}
   */
  getProduct(id, gtin) {
    return new Promise((resolve, reject) => {
      let partnerId = this._sdk.getPartnerId();
      let testing = this._sdk.isTesting();
      let environment = this._sdk.getEnvironment();
      if (!partnerId) {
        let message = 'You must set partnerId to get product information';
        console.error(message);
        reject(new Error(message));
      }
      this._sdk.analytics.sendEvent('RevievePR.getProduct');
      if (this._state.product && this._state.product.id === id) {
        resolve(this._state.product);
        return;
      }
      if (gtin !== undefined && this._state.product && this._state.product.gtin === gtin) {
        resolve(this._state.product);
        return;
      }
      services
        .getProduct({ partnerId, productId: id, productGTIN: gtin, testing, environment })
        .then((response) => {
          if (!response || response instanceof Error) {
            let message = 'Error retrieving product info from server';
            console.error(message);
            reject(new Error(message));
          }
          this._state.product = response.result && response.result.product ? response.result.product : null;
          resolve(this._state.product);
        })
        .catch((error) => {
          if (error) {
            console.error(error);
          }
          reject(error);
        });
    });
  }

  /**
   * Method to get individual product information with children
   *
   * @param {String} id product identifier
   *
   * @param {String} gtin product gtin identifier
   *
   * @returns {Promise}
   */
  getProductWithVariants(id, gtin) {
    return new Promise((resolve, reject) => {
      let partnerId = this._sdk.getPartnerId();
      let testing = this._sdk.isTesting();
      let environment = this._sdk.getEnvironment();
      if (!partnerId) {
        let message = 'You must set partnerId to get product information';
        console.error(message);
        reject(new Error(message));
      }
      this._sdk.analytics.sendEvent('RevievePR.getProduct');
      if (this._state.product && this._state.product.id === id) {
        resolve(this._state.product);
        return;
      }
      if (gtin !== undefined && this._state.product && this._state.product.gtin === gtin) {
        resolve(this._state.product);
        return;
      }
      services
        .getProductWithVariants({ partnerId, productId: id, productGTIN: gtin, testing, environment })
        .then((response) => {
          if (!response || response instanceof Error) {
            let message = 'Error retrieving product info from server';
            console.error(message);
            reject(new Error(message));
          }
          this._state.product = response.result && response.result.product ? response.result.product : null;
          resolve(this._state.product);
        })
        .catch((error) => {
          if (error) {
            console.error(error);
          }
          reject(error);
        });
    });
  }

  /**
   * Method to get list of products information
   *
   * @param {string[]} [productIds] - List of product ids.
   *
   * @returns {Promise}
   */
  getProducts(productIds) {
    return new Promise((resolve, reject) => {
      let partnerId = this._sdk.getPartnerId();
      let testing = this._sdk.isTesting();
      let environment = this._sdk.getEnvironment();
      if (!partnerId) {
        let message = 'You must set partnerId to get product information';
        console.error(message);
        reject(new Error(message));
      }
      this._sdk.analytics.sendEvent('RevievePR.getProducts');
      services
        .getProducts({ partnerId, productIds, testing, environment })
        .then((response) => {
          if (!response) {
            let message = 'Error retrieving products info from server';
            console.error(message);
            reject(new Error(message));
          }
          const products = response?.result?.products ?? null;
          resolve(products);
        })
        .catch((error) => {
          if (error) {
            console.error(error);
          }
          reject(error);
        });
    });
  }

  /**
   * Method to save addToCart related data for reportin and analytics purposes.
   *
   * @param {Object[]} purchases - a list of product info objects
   * @param {string} purchases[].id -  product identifier
   * @param {number} [purchases[].price] - product price
   * @param {*} [purchases[].extra] - product extra info
   * @param {string} [historyId] - historyId, if not provided value from SDK state will be used.
   *
   * @returns {Promise}
   */
  addToCart(purchases, historyId = this._state.historyId) {
    return new Promise((resolve, reject) => {
      let testing = this._sdk.isTesting();
      let environment = this._sdk.getEnvironment();

      if (!historyId) {
        let noHistoryIdError = new Error('historyId is mandatory to send addToCart data');
        console.error(noHistoryIdError.message);
        reject(noHistoryIdError);
      }

      if (!purchases || !purchases.length) {
        let noPurchasesError = new Error('purchases data is empty');
        console.error(noPurchasesError.message);
        reject(noPurchasesError);
      }

      let apiPurchases = purchases.map(({ id, price, extra }) => ({ product_id: id, price, extra }));

      this._sdk.analytics.sendEvent('RevievePR.addToCart');

      addToCart({ historyId, purchases: apiPurchases, testing, environment })
        .then((response) => {
          if (!response || !response.result) {
            let message = 'Error sending addToCart data to server';
            console.error(message);
            reject(new Error(message));
          }
          resolve();
        })
        .catch((error) => {
          if (error) {
            console.error(error);
          }
          reject(error);
        });
    });
  }

  /**
   * Method to get last recommended products based in the parameters setted in SDK state.
   * Please note, this method doesn't request any information to server.
   *
   * @returns {Object} JSON set of recommended products
   */
  getLastRecommendedProducts() {
    return this._state.recommendedProducts;
  }

  /**
   * Method to get history Id of the last getRecommendedProducts call
   *
   * @returns {String} string with history id
   */
  getHistoryId() {
    return this._state.historyId;
  }

  /**
   * Method to get routines of the last getRecommendedProducts call
   *
   * @returns {String} string with history id
   */
  getRoutines() {
    return this._state.routines;
  }

  /**
   * Returns a copy of the state configuration object in SDK
   *
   * @returns {Object} JSON with all configuration values
   */
  getSeralizableState() {
    return rfdc()(this._state);
  }

  /**
   * Hydrate the state with the provided one
   *
   * @param {Object} state JSON with all configuration values
   */
  hydrateState(state) {
    if (state) {
      this._state = rfdc()(state);
    }
  }
}

export default RevievePR;
