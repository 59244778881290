import { hairMeasures, makeupMeasures } from '../appConf';

export const findings = {
  hyperpigmentation: {
    cv: 'hyperpigmentation',
    mask: 'hyperpigmentation',
    path: [{ member: 'root', type: 'array', filter: { member: 'description', value: 'hyperpigmentation' } }],
    type: 'float',
  },
  melasma: {
    cv: 'melasma',
    mask: 'melasma',
    path: [{ member: 'root', type: 'array', filter: { member: 'description', value: 'melasma' } }],
    type: 'float',
  },
  freckles: {
    cv: 'freckles',
    mask: 'freckles',
    path: [{ member: 'root', type: 'array', filter: { member: 'description', value: 'freckles' } }],
    type: 'float',
  },
  darkSpots: {
    normalizationFactor: 3, // compensation for non-normal distribution
    cv: 'dark_spots',
    mask: 'dark_spots',
    path: [{ member: 'root', type: 'array', filter: { member: 'description', value: 'dark_spots' } }],
    type: 'float',
  },
  darkSpotsAdjusted: {
    cv: 'dark_spots',
    mask: 'dark_spots_adjusted',
    path: [{ member: 'root', type: 'array', filter: { member: 'description', value: 'dark_spots_adjusted' } }],
    type: 'float',
  },
  wrinkles: {
    cv: 'wrinkles',
    mask: 'wrinkles',
    path: [{ member: 'root', type: 'array', filter: { member: 'description', value: 'wrinkles' } }],
    type: 'float',
  },
  redness: {
    cv: 'redness',
    mask: 'redness',
    path: [{ member: 'root', type: 'array', filter: { member: 'description', value: 'redness' } }],
    type: 'float',
  },
  rednessAbsolute: {
    cv: 'redness',
    mask: 'redness_absolute',
    path: [{ member: 'root', type: 'array', filter: { member: 'description', value: 'redness_absolute' } }],
    type: 'float',
  },
  texture: {
    cv: 'texture',
    mask: 'texture',
    path: [{ member: 'root', type: 'array', filter: { member: 'description', value: 'texture' } }],
    type: 'float',
  },
  acne: {
    normalizationFactor: 12, // compensation for non-normal distribution
    cv: 'acne',
    mask: 'acne',
    path: [{ member: 'root', type: 'array', filter: { member: 'description', value: 'acne' } }],
    type: 'float',
  },
  smoothness: {
    cv: 'smoothness',
    mask: 'smoothness',
    path: [{ member: 'root', type: 'array', filter: { member: 'description', value: 'smoothness' } }],
    positive: true,
    type: 'float',
  },
  skinShine: {
    cv: 'skin_shine',
    mask: 'Skin shine',
    path: [{ member: 'root', type: 'array', filter: { member: 'description', value: 'Skin shine' } }],
    type: 'float',
  },
  radiance: {
    cv: 'radiance',
    mask: 'radiance',
    path: [{ member: 'root', type: 'array', filter: { member: 'description', value: 'radiance' } }],
    positive: true,
    type: 'float',
  },
  radianceAdjusted: {
    cv: 'radiance',
    mask: 'radiance_adjusted',
    path: [{ member: 'root', type: 'array', filter: { member: 'description', value: 'radiance_adjusted' } }],
    positive: true,
    type: 'float',
  },
  dullSkin: {
    cv: 'dull_skin',
    mask: 'dull_skin',
    path: [{ member: 'root', type: 'array', filter: { member: 'description', value: 'dull_skin' } }],
    type: 'float',
  },
  unevenSkinTone: {
    cv: 'uneven_skintone',
    mask: 'uneven_skin_tone',
    path: [{ member: 'root', type: 'array', filter: { member: 'description', value: 'uneven_skin_tone' } }],
    type: 'float',
  },
  eyebags: {
    cv: 'eyebags',
    mask: 'eyes',
    path: [
      { member: 'root', type: 'array', filter: { member: 'description', value: 'eyes' } },
      { member: 'sum_measures', type: 'array', filter: { member: 'description', value: 'eyebags' } },
    ],
    type: 'float',
  },
  darkcircles: {
    cv: 'dark_circle',
    mask: 'eyes',
    path: [
      { member: 'root', type: 'array', filter: { member: 'description', value: 'eyes' } },
      { member: 'sum_measures', type: 'array', filter: { member: 'description', value: 'dark circles' } },
    ],
    type: 'float',
  },
  poreDilation: {
    cv: 'pore_dilation',
    mask: 'pore_dilation',
    path: [{ member: 'root', type: 'array', filter: { member: 'description', value: 'pore_dilation' } }],
    hasWarnings: true,
    type: 'float',
  },
  skinSagging: {
    cv: 'skin_sagging',
    mask: 'skin_sagging',
    path: [{ member: 'root', type: 'array', filter: { member: 'description', value: 'skin_sagging' } }],
    type: 'float',
  },
  skinFirmness: {
    cv: 'skin_firmness',
    mask: 'skin_firmness',
    path: [{ member: 'root', type: 'array', filter: { member: 'description', value: 'skin_firmness' } }],
    positive: true,
    type: 'float',
  },
  faceShape: {
    cv: 'face_shape',
    path: [
      { member: 'root', type: 'array', filter: { member: 'description', value: 'Makeup' } },
      { member: 'sum_measures', type: 'array', filter: { member: 'description', value: 'Face shape' } },
    ],
    type: 'enum',
    enum: makeupMeasures.faceShape,
  },
  skinUndertone: {
    cv: 'undertone',
    path: [
      { member: 'root', type: 'array', filter: { member: 'description', value: 'Makeup' } },
      { member: 'sum_measures', type: 'array', filter: { member: 'description', value: 'Skin undertone' } },
    ],
    type: 'enum',
    enum: makeupMeasures.skinUndertone,
  },
  eyeColor: {
    cv: 'eye_color',
    path: [
      { member: 'root', type: 'array', filter: { member: 'description', value: 'Makeup' } },
      { member: 'sum_measures', type: 'array', filter: { member: 'description', value: 'Eye color' } },
    ],
    type: 'enum',
    enum: makeupMeasures.eyeColor,
  },
  skinFoundation: {
    // cv: 'skintone_color_cv',
    path: [
      { member: 'root', type: 'array', filter: { member: 'description', value: 'Makeup' } },
      { member: 'sum_measures', type: 'array', filter: { member: 'description', value: 'Skin foundation RGB' } },
    ],
    type: 'skintone',
  },
  skinFitzpatrick: {
    path: [
      { member: 'root', type: 'array', filter: { member: 'description', value: 'Makeup' } },
      {
        member: 'sum_measures',
        type: 'array',
        filter: { member: 'description', value: 'Skin Fitzpatrick type' },
      },
    ],
    type: 'integer',
  },
  hairVolume: {
    cv: 'hair_volume',
    mask: 'hair',
    path: [
      { member: 'root', type: 'array', filter: { member: 'description', value: 'hair' } },
      { member: 'sum_measures', type: 'array', filter: { member: 'description', value: 'Hair volume' } },
    ],
    type: 'float',
  },
  hairFrizziness: {
    cv: 'hair_frizziness',
    mask: 'hair',
    path: [
      { member: 'root', type: 'array', filter: { member: 'description', value: 'hair' } },
      { member: 'sum_measures', type: 'array', filter: { member: 'description', value: 'Hair frizziness' } },
    ],
    type: 'enum',
    enum: hairMeasures.hairFrizziness,
  },
  hairType: {
    cv: 'hair_type',
    mask: 'hair',
    path: [
      { member: 'root', type: 'array', filter: { member: 'description', value: 'hair' } },
      { member: 'sum_measures', type: 'array', filter: { member: 'description', value: 'Hair type' } },
    ],
    type: 'enum',
    enum: hairMeasures.hairType,
  },
  hairColor: {
    cv: 'hair_color',
    mask: 'hair',
    path: [
      { member: 'root', type: 'array', filter: { member: 'description', value: 'hair' } },
      { member: 'sum_measures', type: 'array', filter: { member: 'description', value: 'Hair color names' } },
    ],
    type: 'array',
  },
  hairColorDistribution: {
    cv: 'hair_color_distribution',
    mask: 'hair',
    path: [
      { member: 'root', type: 'array', filter: { member: 'description', value: 'hair' } },
      {
        member: 'sum_measures',
        type: 'array',
        filter: { member: 'description', value: 'Hair color distribution' },
      },
    ],
    type: 'array',
  },
  nasolabialFolds: {
    cv: 'nasolabial_folds',
    mask: 'wrinkles',
    parent: {
      name: 'wrinkles',
      measurements: ['left side nasolabial line', 'right side nasolabial line'],
    },
    type: 'float',
  },
  marionetteLines: {
    cv: 'marionette_lines',
    mask: 'wrinkles',
    parent: {
      name: 'wrinkles',
      measurements: ['left side marionette line', 'right side marionette line'],
    },
    type: 'float',
  },
  underEyeLines: {
    cv: 'under_eye_lines',
    mask: 'wrinkles',
    parent: {
      name: 'wrinkles',
      measurements: ['left side tearline', 'right side tearline'],
    },
    type: 'float',
  },
  foreheadLines: {
    cv: 'forehead_lines',
    mask: 'wrinkles',
    parent: {
      name: 'wrinkles',
      measurements: ['forehead worry lines'],
    },
    type: 'float',
  },
  wrinklesForeheadEyes: {
    cv: 'wrinkles_forehead_eyes',
    mask: 'wrinkles',
    parent: {
      name: 'wrinkles',
      measurements: ['forehead worry lines', 'left side tearline', 'right side tearline'],
    },
    type: 'float',
  },
};

export const skintoneReferences = {
  1: 217,
  2: 179,
  3: 141,
  4: 100,
  5: 50,
  6: 0,
};

export const makeupEnum = {
  faceShape: [1, 2, 3, 4, 5, 6],
  skinUndertone: [-1, 0, 1],
  eyeColor: ['brown', 'blue', 'green'],
};

export const hairEnum = {
  hairType: [0, 1, 2, 3, 4],
  hairFrizziness: [0, 1],
};

export const getOutputType = (type, normalized) => {
  if (type === 'float') return normalized ? 'percentage' : 'float';
  if (type === 'enum') return 'string';
  return type;
};
