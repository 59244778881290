import { getServerUrl } from '../appConf';
import { converImageToBitmap } from '../utils/generalFunctions';
import { checkStatus, fetchWithTimeout } from '../utils/fetch';
import { hexToRgb } from '../utils/color';

export default function analyzeImage(
  apiVersion,
  partnerId,
  image,
  skintone = 0,
  skintoneColor,
  gender = 'female',
  hairtype,
  testing = false,
  environment = 'test',
  components,
  timeout = 60000,
) {
  let serverUrl = getServerUrl(testing, environment);
  let url = serverUrl + 'api/analyzeImage/' + apiVersion + '/';
  url += '?skintone=' + skintone + '&gender=' + gender;
  if (hairtype) url += '&hairtype=' + hairtype;

  if (skintoneColor) {
    const skintoneRGBString = hexToRgb(skintoneColor).join(',');
    url += '&skintone_rgb=' + skintoneRGBString;
  }

  if (components) url += '&components=' + components;
  let data = new FormData();
  data.append('image', converImageToBitmap(image));
  data.append('partner_id', partnerId);
  return fetchWithTimeout(
    url,
    {
      method: 'POST',
      body: data,
    },
    timeout,
  )
    .then(checkStatus)
    .then((response) => response.json())
    .catch((error) => {
      console.error('Request failed', error);
      const extendedError = new Error(error.message);
      extendedError.code = error.code ? error.code : 500;
      throw extendedError;
    });
}
