import { defaultStrength, makeupMeasures } from '../appConf';
import { applyWhiteBalanceToColor, getRangeColor } from '../utils/color';
import { levenshteinDistance } from '../utils/distanceFunctions';
import { draw, drawCircle, drawLine, drawPolygon, highlightArea } from '../utils/draw';
import { convertMaskToArea, getAnimationStyle } from '../utils/effectsAnimation';
import { calculateAspectRatioFit, getImageDimensions } from '../utils/generalFunctions';
import { clearCachedMasks, getMasksShape } from '../utils/geometry';

const initialState = {
  orientation: 'auto', // fits image to the container's width
  needsReset: false,
  scaleFactor: 1,
  layers: {},
  effectHistory: [],
  image: {},
  defaultStrength,
  whiteBalanceCorrection: true,
  whiteBalanceValue: false,
  externalContainer: false,
};
class RevieveAR {
  /**
   * RevieveAR class.
   * Revieve AR image manipulation.
   * @constructor
   * @param {Object} sdk - instance of RevieveSDK
   */
  constructor(sdk) {
    if (!sdk) {
      console.error('RevieveAR: Constructor needs an SDK instance');
      Object.create(null);
    }

    this._sdk = sdk;
    this._state = initialState;

    /** @property {Object} methods
     * Helper list of methods available in module. <br>
     * You have all the methods of the AR module subdivided in six categories:
     * <ul>
     *  <li><strong>configuration</strong> Methods to configure the behaviour of the module:
     *    <ul>
     *      <li>{@link RevieveAR#setContainer}</li>
     *      <li>{@link RevieveAR#setDefaultStrength}</li>
     *      <li>{@link RevieveAR#getDefaultStrength}</li>
     *      <li>{@link RevieveAR#setWhiteBalanceCorrection}</li>
     *      <li>{@link RevieveAR#setOrientation}</li>
     *      <li>{@link RevieveAR#setScaleFactor}</li>
     *    </ul>
     *  </li>
     *  <li><strong>control</strong> Methods to control the flow of the module,
     * like redraw effects, etc.
     *    <ul>
     *      <li>{@link RevieveAR#redrawEffects}</li>
     *      <li>{@link RevieveAR#reset}</li>
     *      <li>{@link RevieveAR#drawOriginalImage}</li>
     *      <li>{@link RevieveAR#testImages}</li>
     *      <li>{@link RevieveAR#getResultsDiv}</li>
     *      <li>{@link RevieveAR#getResultsImage}</li>
     *    </ul>
     *  </li>
     *  </li>
     *  <li><strong>highlight</strong> Methods to apply highlight effects
     *    <ul>
     *      <li>{@link RevieveAR#highlightFaceShape}</li>
     *      <li>{@link RevieveAR#highlightDarkcircles}</li>
     *      <li>{@link RevieveAR#highlightEyebags}</li>
     *      <li>{@link RevieveAR#highlightHyperpigmentation}</li>
     *      <li>{@link RevieveAR#highlightHyperpigmentationIndividually}</li>
     *      <li>{@link RevieveAR#highlightMelasma}</li>
     *      <li>{@link RevieveAR#highlightMelasmaIndividually}</li>
     *      <li>{@link RevieveAR#highlightFreckles}</li>
     *      <li>{@link RevieveAR#highlightFrecklesIndividually}</li>
     *      <li>{@link RevieveAR#highlightDarkSpots}</li>
     *      <li>{@link RevieveAR#highlightDarkSpotsIndividually}</li>
     *      <li>{@link RevieveAR#highlightUnevenSkinTone}</li>
     *      <li>{@link RevieveAR#highlightUnevenSkinToneIndividually}</li>
     *      <li>{@link RevieveAR#highlightTexture}</li>
     *      <li>{@link RevieveAR#highlightTextureIndividually}</li>
     *      <li>{@link RevieveAR#highlightPoreDilationIndividually}</li>
     *      <li>{@link RevieveAR#highlightWrinkles}</li>
     *      <li>{@link RevieveAR#highlightWrinklesIndividually}</li>
     *      <li>{@link RevieveAR#highlightSmoothness}</li>
     *      <li>{@link RevieveAR#highlightRedness}</li>
     *      <li>{@link RevieveAR#highlightRednessIndividually}</li>
     *      <li>{@link RevieveAR#highlightEyeColor}</li>
     *      <li>{@link RevieveAR#highlightEyesArea}</li>
     *      <li>{@link RevieveAR#highlightCheekArea}</li>
     *      <li>{@link RevieveAR#highlightSkinUndertone}</li>
     *      <li>{@link RevieveAR#highlightSkinFoundation}</li>
     *      <li>{@link RevieveAR#highlightSkinShine}</li>
     *      <li>{@link RevieveAR#highlightAcne}</li>
     *      <li>{@link RevieveAR#highlightAcneIndividually}</li>
     *      <li>{@link RevieveAR#highlightHyperpigmentationAreaValues}</li>
     *      <li>{@link RevieveAR#highlightMelasmaAreaValues}</li>
     *      <li>{@link RevieveAR#highlightFrecklesAreaValues}</li>
     *      <li>{@link RevieveAR#highlightDarkSpotsAreaValues}</li>
     *      <li>{@link RevieveAR#highlightWrinklesAreaValues}</li>
     *      <li>{@link RevieveAR#highlightRednessAreaValues}</li>
     *      <li>{@link RevieveAR#highlightTextureAreaValues}</li>
     *      <li>{@link RevieveAR#highlightAcneAreaValues}</li>
     *      <li>{@link RevieveAR#highlightSmoothnessAreaValues}</li>
     *      <li>{@link RevieveAR#highlightUnevenSkinToneAreaValues}</li>
     *      <li>{@link RevieveAR#highlightEyebagsAreaValues}</li>
     *      <li>{@link RevieveAR#highlightDarkcirclesAreaValues}</li>
     *      <li>{@link RevieveAR#highlightSkinSaggingIndividually}</li>
     *      <li>{@link RevieveAR#dehighlightAll}</li>
     *    </ul>
     *  </li>
     *  <li><strong>skincare</strong> Methods to apply skincare effects
     *    <ul>
     *      <li>{@link RevieveAR#reduceEyebags}</li>
     *      <li>{@link RevieveAR#reduceCrowsFeet}</li>
     *      <li>{@link RevieveAR#reduceDarkcircles}</li>
     *      <li>{@link RevieveAR#reduceRedness}</li>
     *      <li>{@link RevieveAR#reduceHyperpigmentation}</li>
     *      <li>{@link RevieveAR#reduceWrinkles}</li>
     *      <li>{@link RevieveAR#brightenSkin}</li>
     *    </ul>
     *  </li>
     *  <li><strong>makeup</strong> Methods to apply makeup effects
     *    <ul>
     *      <li>{@link RevieveAR#applyLipstick}</li>
     *      <li>{@link RevieveAR#applyLipliner}</li>
     *      <li>{@link RevieveAR#applyEyeliner}</li>
     *      <li>{@link RevieveAR#applyEyeshadow}</li>
     *      <li>{@link RevieveAR#applyBlush}</li>
     *      <li>{@link RevieveAR#applyFoundation}</li>
     *    </ul>
     *  </li>
     *  <li><strong>product</strong> Methods to interact with product visualization.
     *    <ul>
     *      <li>{@link RevieveAR#visualizeProduct}</li>
     *      <li>{@link RevieveAR#canVisualizeProduct}</li>
     *      <li>{@link RevieveAR#getColorInfoFromProduct}</li>
     *      <li>{@link RevieveAR#getDefaultStregthFromProduct}</li>
     *    </ul>
     *  </li>
     * </ul>
     */
    this.methods = {
      configuration: {
        setContainer: this.setContainer.bind(this),
        setDefaultStrength: this.setDefaultStrength.bind(this),
        getDefaultStrength: this.getDefaultStrength.bind(this),
        setWhiteBalanceCorrection: this.setWhiteBalanceCorrection.bind(this),
        setOrientation: this.setOrientation.bind(this),
        setScaleFactor: this.setScaleFactor.bind(this),
      },
      control: {
        redrawEffects: this.redrawEffects.bind(this),
        reset: this.reset.bind(this),
        drawOriginalImage: this.drawOriginalImage.bind(this),
        testImages: this.testImages.bind(this),
        getResultsDiv: this.getResultsDiv.bind(this),
      },
      highlight: {
        highlightFaceShape: this.highlightFaceShape.bind(this),
        highlightDarkcircles: this.highlightDarkcircles.bind(this),
        highlightEyebags: this.highlightEyebags.bind(this),
        highlightHyperpigmentation: this.highlightHyperpigmentation.bind(this),
        highlightHyperpigmentationIndividually: this.highlightHyperpigmentationIndividually.bind(this),
        highlightMelasma: this.highlightMelasma.bind(this),
        highlightMelasmaIndividually: this.highlightMelasmaIndividually.bind(this),
        highlightFreckles: this.highlightFreckles.bind(this),
        highlightFrecklesIndividually: this.highlightFrecklesIndividually.bind(this),
        highlightDarkSpots: this.highlightDarkSpots.bind(this),
        highlightDarkSpotsIndividually: this.highlightDarkSpotsIndividually.bind(this),
        highlightUnevenSkinTone: this.highlightUnevenSkinTone.bind(this),
        highlightUnevenSkinToneIndividually: this.highlightUnevenSkinToneIndividually.bind(this),
        highlightRedness: this.highlightRedness.bind(this),
        highlightRednessIndividually: this.highlightRednessIndividually.bind(this),
        highlightTexture: this.highlightTexture.bind(this),
        highlightTextureIndividually: this.highlightTextureIndividually.bind(this),
        highlightPoreDilationIndividually: this.highlightPoreDilationIndividually.bind(this),
        highlightWrinkles: this.highlightWrinkles.bind(this),
        highlightWrinklesIndividually: this.highlightWrinklesIndividually.bind(this),
        highlightSmoothness: this.highlightSmoothness.bind(this),
        highlightEyeColor: this.highlightEyeColor.bind(this),
        highlightEyesArea: this.highlightEyesArea.bind(this),
        highlightCheekArea: this.highlightCheekArea.bind(this),
        highlightSkinUndertone: this.highlightSkinUndertone.bind(this),
        highlightSkinFoundation: this.highlightSkinFoundation.bind(this),
        highlightSkinShine: this.highlightSkinShine.bind(this),
        highlightAcne: this.highlightAcne.bind(this),
        highlightAcneIndividually: this.highlightAcneIndividually.bind(this),
        highlightHyperpigmentationAreaValues: this.highlightHyperpigmentationAreaValues.bind(this),
        highlightMelasmaAreaValues: this.highlightMelasmaAreaValues.bind(this),
        highlightFrecklesAreaValues: this.highlightFrecklesAreaValues.bind(this),
        highlightDarkSpotsAreaValues: this.highlightDarkSpotsAreaValues.bind(this),
        highlightWrinklesAreaValues: this.highlightWrinklesAreaValues.bind(this),
        highlightRednessAreaValues: this.highlightRednessAreaValues.bind(this),
        highlightTextureAreaValues: this.highlightTextureAreaValues.bind(this),
        highlightAcneAreaValues: this.highlightAcneAreaValues.bind(this),
        highlightSmoothnessAreaValues: this.highlightSmoothnessAreaValues.bind(this),
        highlightUnevenSkinToneAreaValues: this.highlightUnevenSkinToneAreaValues.bind(this),
        highlightEyebagsAreaValues: this.highlightEyebagsAreaValues.bind(this),
        highlightDarkcirclesAreaValues: this.highlightDarkcirclesAreaValues.bind(this),
        highlightSkinSaggingIndividually: this.highlightSkinSaggingIndividually.bind(this),
        dehighlightAll: this.dehighlightAll.bind(this),
      },
      skincare: {
        reduceEyebags: this.reduceEyebags.bind(this),
        reduceCrowsFeet: this.reduceCrowsFeet.bind(this),
        reduceDarkcircles: this.reduceDarkcircles.bind(this),
        reduceRedness: this.reduceRedness.bind(this),
        reduceHyperpigmentation: this.reduceHyperpigmentation.bind(this),
        reduceWrinkles: this.reduceWrinkles.bind(this),
        brightenSkin: this.brightenSkin.bind(this),
      },
      makeup: {
        applyLipstick: this.applyLipstick.bind(this),
        applyLipliner: this.applyLipliner.bind(this),
        applyEyeliner: this.applyEyeliner.bind(this),
        applyEyeshadow: this.applyEyeshadow.bind(this),
        applyBlush: this.applyBlush.bind(this),
        applyFoundation: this.applyFoundation.bind(this),
      },
      product: {
        visualizeProduct: this.visualizeProduct.bind(this),
        canVisualizeProduct: this.canVisualizeProduct.bind(this),
        getColorInfoFromProduct: this.getColorInfoFromProduct.bind(this),
        getDefaultStrengthFromProduct: this.getDefaultStrengthFromProduct.bind(this),
      },
    };
  }

  /**
   * Sets the container Id where you want to render automatically results
   * of setting effects
   *
   * @param {String | Object} container - valid HTML Element or HTML tag id
   * @param {Boolean} disableOnResize - set to true if you don't want to redraw
   * all the events on window resize
   */
  setContainer(container, disableOnResize = false) {
    const containerObject = typeof container === 'object' ? container : document.getElementById(container);
    if (containerObject) {
      this.reset(false);
      this._state.container = containerObject;
      this._state.externalContainer = true;
      if (window && !disableOnResize) {
        window.onresize = () => {
          // we only resize when the container width has changed
          if (this._state.containerWidth !== containerObject.offsetWidth) {
            this._state.containerWidth = containerObject.offsetWidth;
            this.redrawEffects();
          }
        };
      }
    } else {
      console.error('You must set a valid container ID');
    }
  }

  /** Method to draw original image in the container
   */
  async drawOriginalImage() {
    const drawImage = () => {
      const width = this._state.finalDimensions ? this._state.finalDimensions.width : this._state.image.width;
      const height = this._state.finalDimensions ? this._state.finalDimensions.height : this._state.image.height;
      if (!this._resultDiv) {
        // we need to create a new div
        this._resultDiv = document.createElement('div');
        this._resultDiv.style.position = 'relative';
        this._resultDiv.style.width = width;
        this._resultDiv.style.height = height;
        const container = this.getContainer();
        container.appendChild(this._resultDiv);
      }

      for (let children of this._resultDiv.children) {
        if (children.tagName.toLowerCase() === 'img') {
          return;
        }
      }
      const originalImage = document.createElement('img');
      originalImage.src = this._sdk.CV.getImage();
      originalImage.width = width;
      originalImage.height = height;
      originalImage.alt = 'selfie';
      this._resultDiv.appendChild(originalImage);
    };
    // image is initialized?
    if (this._state.image.width) {
      drawImage();
    } else {
      await this.initializeImage();
      drawImage();
    }
  }

  /** Method to update the default strength of the effects
   * @param {float} strength - default strength to apply in all effects
   */
  setDefaultStrength(strength) {
    this._state.defaultStrength = strength;
  }

  /**
   * Methot to get the default strength defined by AR or user
   * @returns {float} default strength
   */
  getDefaultStrength() {
    return this._state.defaultStrength;
  }

  /** Method to set the scale factor to apply in animation style returned by highlight methods. For example, if you want to increase the zoom a 20%, you will set the factor to 1.2
   * @param {float} factor - factor to apply.
   */
  setScaleFactor(factor) {
    this._state.scaleFactor = factor;
  }

  /** Activates or deactivates the white balance correction feature
   * @param {Boolean} value - true if you want to activate or false to deactive
   * white balance feature when you apply effects
   */
  setWhiteBalanceCorrection(value) {
    this._state.whiteBalanceCorrection = value;
  }

  /**
   * Method to redraw all the effects applied until now
   */
  redrawEffects() {
    if (this._state.effectHistory && this._state.effectHistory.length > 0) {
      if (this._resultDiv) {
        for (let divChildren of this._resultDiv.children) {
          this._resultDiv.removeChild(divChildren);
        }
        this._resultDiv.parentNode.removeChild(this._resultDiv);
        this._resultDiv = null;
      }
      this._state.effectHistory.map((effect) => {
        this.setEffectByName(
          effect.effectName,
          effect.masks,
          effect.strength,
          effect.fillColorOriginal,
          effect.highlight,
          effect.highlightShape,
          effect.ranges,
        );
        return true;
      });
    } else {
      this.drawOriginalImage();
    }
  }

  /**
   * Sets the orientation of the container to fit image and canvas properly
   *
   * @param {String} orientation - landscape, portrait or auto
   */
  setOrientation(orientation) {
    this._state.orientation = orientation;
  }

  /**
   * Reduce eyebags in the user image.
   * Resulting effects will be applied to div containing the image returned by getResults
   * or to the container specified in constructor.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect
   * @see [getResults]{@link RevieveAR#getResults}
   * @param {Float=} strength - Strength of the reduction effect.
   * See {@link RevieveAR.defaultStrength}
   */
  reduceEyebags(strength) {
    this._sdk.analytics.sendEvent('RevieveAR.reduceEyebags', strength);
    return this.setEffectByName('reduceEyebags', null, strength);
  }

  /**
   * Reduce crows feet in the user image.
   * Resulting effects will be applied to div containing the image returned by getResults
   * or to the container specified in constructor.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect
   * @see [getResults]{@link RevieveAR#getResults}
   * @param {Float=} strength - Strength of the reduction effect.
   * See {@link RevieveAR.defaultStrength}
   */
  reduceCrowsFeet(strength) {
    this._sdk.analytics.sendEvent('RevieveAR.reduceCrowsFeet', strength);
    return this.setEffectByName('reduceCrowsFeet', null, strength);
  }

  /**
   * Reduce dark circles in the user image.
   * Resulting effects will be applied to div containing the image returned by getResults
   * or to the container specified in constructor.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect
   * @see [getResults]{@link RevieveAR#getResults}
   * @param {Float=} strength - Strength of the reduction effect.
   * See {@link RevieveAR.defaultStrength}
   */
  reduceDarkcircles(strength) {
    this._sdk.analytics.sendEvent('RevieveAR.reduceDarkcircles', strength);
    return this.setEffectByName('reduceDarkcircles', null, strength);
  }

  /**
   * Reduce redness in the user image areas defined in masks parameter.
   * Resulting effects will be applied to div containing the image returned by getResults
   * or to the container specified in constructor.
   *@returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect
   * @see [getResults]{@link RevieveAR#getResults}
   * @param {Float=} strength - Strength of the reduction effect.
   * See {@link RevieveAR.defaultStrength}
   * @param {String[]} masks - Array of {@link RevieveAR.masks} defining the areas
   * where the effect is going to be applied.
   */
  reduceRedness(strength, masks) {
    this._sdk.analytics.sendEvent('RevieveAR.reduceRedness', strength + '_' + JSON.stringify(masks));
    return this.setEffectByName('reduceRedness', masks, strength);
  }

  /**
   * Reduce hyperpigmentated spots in the user image areas defined in masks parameter.
   * Resulting effects will be applied to div containing the image returned by getResults
   * or to the container specified in constructor.
   *@returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect
   * @see [getResults]{@link RevieveAR#getResults}
   * @param {Float=} strength - Strength of the reduction effect.
   * See {@link RevieveAR.defaultStrength}
   * @param {String[]} masks - Array of {@link RevieveAR.masks} defining the areas
   * where the effect is going to be applied.
   */
  reduceHyperpigmentation(strength, masks) {
    // we need to check if hyperpigmentation_visualization component is include in CV module
    if (
      !this._sdk.getConfiguration().components ||
      !this._sdk.getConfiguration().components.includes('hyperpigmentation_visualization')
    ) {
      return new Promise((resolve, reject) => {
        this._state.error = true;
        let message = 'You need hyperpigmentation_visualization component active in CV module to apply this effect';
        console.error(message);
        reject(new Error(message));
      });
    }
    this._sdk.analytics.sendEvent('RevieveAR.reduceHyperpigmentation', strength + '_' + JSON.stringify(masks));
    return this.setEffectByName('reduceHyperpigmentation', masks, strength);
  }

  /**
   * Reduce wrinkles in the user image areas defined in masks parameter.
   * Resulting effects will be applied to div containing the image returned by getResults
   * or to the container specified in constructor.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect
   * @see [getResults]{@link RevieveAR#getResults}
   * @param {Float=} strength - Strength of the reduction effect.
   * See {@link RevieveAR.defaultStrength}
   * @param {String[]} masks - Array of {@link RevieveAR.masks} defining the areas
   * where the effect is going to be applied.
   */
  reduceWrinkles(strength, masks) {
    this._sdk.analytics.sendEvent('RevieveAR.reduceWrinkles', strength + '_' + JSON.stringify(masks));
    return this.setEffectByName('reduceWrinkles', masks, strength);
  }

  /**
   * Brighten the skin in the user image areas defined in masks parameter.
   * Resulting effects will be applied to div containing the image returned by getResults
   * or to the container specified in constructor.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect
   * @see [getResults]{@link RevieveAR#getResults}
   * @param {Float=} strength - Strength of the reduction effect.
   * See {@link RevieveAR.defaultStrength}
   * @param {String[]} masks - Array of {@link RevieveAR.masks} defining the areas
   * where the effect is going to be applied.
   */
  brightenSkin(strength, masks) {
    this._sdk.analytics.sendEvent('RevieveAR.brightenSkin', strength + '_' + JSON.stringify(masks));
    return this.setEffectByName('brightenSkin', masks, strength);
  }

  /**
   * Apply lipstick in the user image.
   * Resulting effects will be applied to div containing the image returned by getResults
   * or to the container specified in constructor.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect
   * @see [getResults]{@link RevieveAR#getResults}
   * @param {Float=} strength - Strength of the effect.
   * See {@link RevieveAR.defaultStrength}
   * @param {String} color - rgb, hex or name of the lipstick's color you want to be applied.
   */
  applyLipstick(strength, color) {
    this._sdk.analytics.sendEvent('RevieveAR.applyLipstick', strength + '_' + color);
    return this.setEffectByName('applyLipstick', null, strength, color);
  }

  /**
   * Apply lipliner in the user image.
   * Resulting effects will be applied to div containing the image returned by getResults
   * or to the container specified in constructor.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect
   * @see [getResults]{@link RevieveAR#getResults}
   * @param {Float=} strength - Strength of the effect.
   * See {@link RevieveAR.defaultStrength}
   * @param {String} color - rgb, hex or name of the lipliner's color you want to be applied.
   */
  applyLipliner(strength, color) {
    this._sdk.analytics.sendEvent('RevieveAR.applyLipliner', strength + '_' + color);
    return this.setEffectByName('applyLipliner', null, strength, color);
  }

  /**
   * Apply eyeliner in the user image.
   * Resulting effects will be applied to div containing the image returned by getResults
   * or to the container specified in constructor.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect
   * @see [getResults]{@link RevieveAR#getResults}
   * @param {Float=} strength - Strength of the effect.
   * See {@link RevieveAR.defaultStrength}
   * @param {String} color - rgb, hex or name of the eyeliner's color you want to be applied.
   */
  applyEyeliner(strength, color) {
    this._sdk.analytics.sendEvent('RevieveAR.applyEyeliner', strength + '_' + color);
    return this.setEffectByName('applyEyeliner', null, strength, color);
  }

  /**
   * Apply eyeshadow in the user image.
   * Resulting effects will be applied to div containing the image returned by getResults
   * or to the container specified in constructor.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect
   * @see [getResults]{@link RevieveAR#getResults}
   * @param {Float=} strength - Strength of the effect.
   * See {@link RevieveAR.defaultStrength}
   * @param {String} color - rgb, hex or name of the eyeshadow's color you want to be applied.
   */
  applyEyeshadow(strength, color) {
    this._sdk.analytics.sendEvent('RevieveAR.applyEyeshadow', strength + '_' + color);
    return this.setEffectByName('applyEyeshadow', null, strength, color);
  }

  /**
   * Apply blush in the user image.
   * Resulting effects will be applied to div containing the image returned by getResults
   * or to the container specified in constructor.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect
   * @see [getResults]{@link RevieveAR#getResults}
   * @param {Float=} strength - Strength of the effect.
   * See {@link RevieveAR.defaultStrength}
   * @param {String} color - rgb, hex or name of the blush's color you want to be applied.
   */
  applyBlush(strength, color) {
    this._sdk.analytics.sendEvent('RevieveAR.applyBlush', strength + '_' + color);
    return this.setEffectByName('applyBlush', null, strength, color);
  }

  /**
   * Apply foundation in the user image.
   * Resulting effects will be applied to div containing the image returned by getResults
   * or to the container specified in constructor.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect
   * @see [getResults]{@link RevieveAR#getResults}
   * @param {Float=} strength - Strength of the effect.
   * See {@link RevieveAR.defaultStrength}
   * @param {String} color - rgb, hex or name of the foundation's color you want to be applied.
   */
  applyFoundation(strength, color) {
    this._sdk.analytics.sendEvent('RevieveAR.applyFoundation', strength + '_' + color);
    return this.setEffectByName('applyFoundation', null, strength, color);
  }

  /**
   * Highlight a finding detected by CV module.
   * Resulting effects will be applied to div containing the image returned by getResults
   * or to the container specified in constructor.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect
   * @see [getResults]{@link RevieveAR#getResults}
   * @param {String} findingName - String with the finding name.
   * @param {String[]} masks - Array of {@link RevieveAR.masks} defining the areas
   * where you want to highlight the concern.
   * Null or empty array will highlight the worst area detected by CV module.
   @param {String=|Object=} shapeConfig - You can define a rgb, hex or name of color to be used to darken the image or you can define
   * an object with this structure: {fill: {color: <color to use to fill>},
   * stroke: {color: color to use to stroke, width: width of the stroke line,
   * lineDash: two dimensions array where first dimension is the width of the dash and second one the space between points}}
   * Keep in mind that this properties are optional, so you can define just fill, stroke or both.
   */
  highlightFinding(findingName, masks, shapeConfig) {
    const highlightFunctions = {
      hyperpigmentation: () => this.highlightHyperpigmentation(masks, shapeConfig),
      melasma: () => this.highlightMelasma(masks, shapeConfig),
      freckles: () => this.highlightFreckles(masks, shapeConfig),
      darkSpots: () => this.highlightDarkSpots(masks, shapeConfig),
      unevenSkinTone: () => this.highlightUnevenSkinTone(masks, shapeConfig),
      wrinkles: () => this.highlightWrinkles(masks, shapeConfig),
      redness: () => this.highlightRedness(masks, shapeConfig),
      texture: () => this.highlightTexture(masks, shapeConfig),
      skinShine: () => this.highlightSkinShine(masks, shapeConfig),
      dullSkin: () => {},
      radiance: () => {},
      acne: () => this.highlightAcne(masks, shapeConfig),
      smoothness: () => this.highlightSmoothness(masks, shapeConfig),
      eyebags: () => this.highlightEyebags(masks, shapeConfig),
      darkcircles: () => this.highlightDarkcircles(masks, shapeConfig),
      faceShape: () => this.highlightFaceShape(masks, shapeConfig),
      eyeColor: () => this.highlightEyeColor(masks, shapeConfig),
      skinUndertone: () => this.highlightSkinUndertone(masks, shapeConfig),
      poreDilation: () => {},
      skinSagging: () => {},
      skinFirmness: () => {},
    };

    const highlightFunction = highlightFunctions[findingName];
    return highlightFunction ? highlightFunction() : null;
  }

  /**
   * Highlight darkcircles detected by CV module.
   * Resulting effects will be applied to div containing the image returned by getResults
   * or to the container specified in constructor.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect
   * @see [getResults]{@link RevieveAR#getResults}
   * @param {String[]} masks - Array of {@link RevieveAR.masks} defining the areas
   * where you want to highlight the concern.
   * Null or empty array will highlight the worst area detected by CV module.
   * @param {String=|Object=} shapeConfig - You can define a rgb, hex or name of color to be used to darken the image or you can define
   * an object with this structure: {fill: {color: <color to use to fill>},
   * stroke: {color: color to use to stroke, width: width of the stroke line,
   * lineDash: two dimensions array where first dimension is the width of the dash and second one the space between points}}
   * Keep in mind that this properties are optional, so you can define just fill, stroke or both.
   */
  highlightDarkcircles(masks, shapeConfig) {
    this._sdk.analytics.sendEvent('RevieveAR.highlightDarkcircles', JSON.stringify(masks));
    return shapeConfig === undefined || typeof shapeConfig === 'string'
      ? this.highlightMasksForSection('darkcircles', masks, shapeConfig)
      : this.highlightMasksForSection('darkcircles', masks, null, shapeConfig);
  }

  /**
   * Highlight eye bags detected by CV module.
   * Resulting effects will be applied to div containing the image returned by getResults
   * or to the container specified in constructor.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect
   * @see [getResults]{@link RevieveAR#getResults}
   * @param {String[]} masks - Array of {@link RevieveAR.masks} defining the areas
   * where you want to highlight the concern.
   * Null or empty array will highlight the worst area detected by CV module.
   * @param {String=|Object=} shapeConfig - You can define a rgb, hex or name of color to be used to darken the image or you can define
   * an object with this structure: {fill: {color: <color to use to fill>},
   * stroke: {color: color to use to stroke, width: width of the stroke line,
   * lineDash: two dimensions array where first dimension is the width of the dash and second one the space between points}}
   * Keep in mind that this properties are optional, so you can define just fill, stroke or both.
   */
  highlightEyebags(masks, shapeConfig) {
    this._sdk.analytics.sendEvent('RevieveAR.highlightEyebags', JSON.stringify(masks));
    return shapeConfig === undefined || typeof shapeConfig === 'string'
      ? this.highlightMasksForSection('eyebags', masks, shapeConfig)
      : this.highlightMasksForSection('eyebags', masks, null, shapeConfig);
  }

  /**
   * Highlight areas with hyperpigmentation detected by CV module.
   * Resulting effects will be applied to div containing the image returned by getResults
   * or to the container specified in constructor.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect
   * @see [getResults]{@link RevieveAR#getResults}
   * @param {String} masks - Array of {@link RevieveAR.masks} defining the areas
   * where you want to highlight the concern.
   * Null or empty array will highlight the worst area detected by CV module.
   * @param {String=|Object=} shapeConfig - You can define a rgb, hex or name of color to be used to darken the image or you can define
   * an object with this structure: {fill: {color: <color to use to fill>},
   * stroke: {color: color to use to stroke, width: width of the stroke line,
   * lineDash: two dimensions array where first dimension is the width of the dash and second one the space between points}}
   * Keep in mind that this properties are optional, so you can define just fill, stroke or both.
   */
  highlightHyperpigmentation(masks, shapeConfig) {
    this._sdk.analytics.sendEvent('RevieveAR.highlightHyperpigmentation', JSON.stringify(masks));
    return shapeConfig === undefined || typeof shapeConfig === 'string'
      ? this.highlightMasksForSection('hyperpigmentation', masks, shapeConfig)
      : this.highlightMasksForSection('hyperpigmentation', masks, null, shapeConfig);
  }

  /**
   * Highlight areas with melasma detected by CV module.
   * Resulting effects will be applied to div containing the image returned by getResults
   * or to the container specified in constructor.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect
   * @see [getResults]{@link RevieveAR#getResults}
   * @param {String} masks - Array of {@link RevieveAR.masks} defining the areas
   * where you want to highlight the concern.
   * Null or empty array will highlight the worst area detected by CV module.
   * @param {String=|Object=} shapeConfig - You can define a rgb, hex or name of color to be used to darken the image or you can define
   * an object with this structure: {fill: {color: <color to use to fill>},
   * stroke: {color: color to use to stroke, width: width of the stroke line,
   * lineDash: two dimensions array where first dimension is the width of the dash and second one the space between points}}
   * Keep in mind that this properties are optional, so you can define just fill, stroke or both.
   */
  highlightMelasma(masks, shapeConfig) {
    this._sdk.analytics.sendEvent('RevieveAR.highlightMelasma', JSON.stringify(masks));
    return shapeConfig === undefined || typeof shapeConfig === 'string'
      ? this.highlightMasksForSection('melasma', masks, shapeConfig)
      : this.highlightMasksForSection('melasma', masks, null, shapeConfig);
  }

  /**
   * Highlight areas with freckles detected by CV module.
   * Resulting effects will be applied to div containing the image returned by getResults
   * or to the container specified in constructor.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect
   * @see [getResults]{@link RevieveAR#getResults}
   * @param {String} masks - Array of {@link RevieveAR.masks} defining the areas
   * where you want to highlight the concern.
   * Null or empty array will highlight the worst area detected by CV module.
   * @param {String=|Object=} shapeConfig - You can define a rgb, hex or name of color to be used to darken the image or you can define
   * an object with this structure: {fill: {color: <color to use to fill>},
   * stroke: {color: color to use to stroke, width: width of the stroke line,
   * lineDash: two dimensions array where first dimension is the width of the dash and second one the space between points}}
   * Keep in mind that this properties are optional, so you can define just fill, stroke or both.
   */
  highlightFreckles(masks, shapeConfig) {
    this._sdk.analytics.sendEvent('RevieveAR.highlightFreckles', JSON.stringify(masks));
    return shapeConfig === undefined || typeof shapeConfig === 'string'
      ? this.highlightMasksForSection('freckles', masks, shapeConfig)
      : this.highlightMasksForSection('freckles', masks, null, shapeConfig);
  }

  /**
   * Highlight areas with dark spots detected by CV module.
   * Resulting effects will be applied to div containing the image returned by getResults
   * or to the container specified in constructor.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect
   * @see [getResults]{@link RevieveAR#getResults}
   * @param {String} masks - Array of {@link RevieveAR.masks} defining the areas
   * where you want to highlight the concern.
   * Null or empty array will highlight the worst area detected by CV module.
   * @param {String=|Object=} shapeConfig - You can define a rgb, hex or name of color to be used to darken the image or you can define
   * an object with this structure: {fill: {color: <color to use to fill>},
   * stroke: {color: color to use to stroke, width: width of the stroke line,
   * lineDash: two dimensions array where first dimension is the width of the dash and second one the space between points}}
   * Keep in mind that this properties are optional, so you can define just fill, stroke or both.
   */
  highlightDarkSpots(masks, shapeConfig) {
    this._sdk.analytics.sendEvent('RevieveAR.highlightDarkSpots', JSON.stringify(masks));
    return shapeConfig === undefined || typeof shapeConfig === 'string'
      ? this.highlightMasksForSection('dark_spots', masks, shapeConfig)
      : this.highlightMasksForSection('dark_spots', masks, null, shapeConfig);
  }

  /**
   * Highlight areas with uneven skin tone problems detected by CV module.
   * Resulting effects will be applied to div containing the image returned by getResults
   * or to the container specified in constructor.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect
   * @see [getResults]{@link RevieveAR#getResults}
   * @param {String} masks - Array of {@link RevieveAR.masks} defining the areas
   * where you want to highlight the concern.
   * Null or empty array will highlight the worst area detected by CV module.
   * @param {String=|Object=} shapeConfig - You can define a rgb, hex or name of color to be used to darken the image or you can define
   * an object with this structure: {fill: {color: <color to use to fill>},
   * stroke: {color: color to use to stroke, width: width of the stroke line,
   * lineDash: two dimensions array where first dimension is the width of the dash and second one the space between points}}
   * Keep in mind that this properties are optional, so you can define just fill, stroke or both.
   */
  highlightUnevenSkinTone(masks, shapeConfig) {
    this._sdk.analytics.sendEvent('RevieveAR.highlightUnevenSkinTone', JSON.stringify(masks));
    return shapeConfig === undefined || typeof shapeConfig === 'string'
      ? this.highlightMasksForSection('uneven_skin_tone', masks, shapeConfig)
      : this.highlightMasksForSection('uneven_skin_tone', masks, null, shapeConfig);
  }

  /**
   * Highlight areas with acne detected by CV module.
   * Resulting effects will be applied to div containing the image returned by getResults
   * or to the container specified in constructor.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect
   * @see [getResults]{@link RevieveAR#getResults}
   * @param {String[]} masks - Array of {@link RevieveAR.masks} defining the areas
   * where you want to highlight the concern.
   * Null or empty array will highlight the worst area detected by CV module.
   * @param {String=|Object=} shapeConfig - You can define a rgb, hex or name of color to be used to darken the image or you can define
   * an object with this structure: {fill: {color: <color to use to fill>},
   * stroke: {color: color to use to stroke, width: width of the stroke line,
   * lineDash: two dimensions array where first dimension is the width of the dash and second one the space between points}}
   * Keep in mind that this properties are optional, so you can define just fill, stroke or both.
   */
  highlightAcne(masks, shapeConfig) {
    this._sdk.analytics.sendEvent('RevieveAR.highlightAcne', JSON.stringify(masks));
    return shapeConfig === undefined || typeof shapeConfig === 'string'
      ? this.highlightMasksForSection('acne', masks, shapeConfig)
      : this.highlightMasksForSection('acne', masks, null, shapeConfig);
  }

  /**
   * Highlight areas with redness detected by CV module.
   * Resulting effects will be applied to div containing the image returned by getResults
   * or to the container specified in constructor.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect
   * @see [getResults]{@link RevieveAR#getResults}
   * @param {String[]} masks - Array of {@link RevieveAR.masks} defining the areas
   * where you want to highlight the concern.
   * Null or empty array will highlight the worst area detected by CV module.
   * @param {String=|Object=} shapeConfig - You can define a rgb, hex or name of color to be used to darken the image or you can define
   * an object with this structure: {fill: {color: <color to use to fill>},
   * stroke: {color: color to use to stroke, width: width of the stroke line,
   * lineDash: two dimensions array where first dimension is the width of the dash and second one the space between points}}
   * Keep in mind that this properties are optional, so you can define just fill, stroke or both.
   */
  highlightRedness(masks, shapeConfig) {
    this._sdk.analytics.sendEvent('RevieveAR.highlightRedness', JSON.stringify(masks));
    return shapeConfig === undefined || typeof shapeConfig === 'string'
      ? this.highlightMasksForSection('redness', masks, shapeConfig)
      : this.highlightMasksForSection('redness', masks, null, shapeConfig);
  }

  /**
   * Highlight areas with texture detected by CV module.
   * Resulting effects will be applied to div containing the image returned by getResults
   * or to the container specified in constructor.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect
   * @see [getResults]{@link RevieveAR#getResults}
   * @param {String[]} masks - Array of {@link RevieveAR.masks} defining the areas
   * where you want to highlight the concern.
   * Null or empty array will highlight the worst area detected by CV module.
   * @param {String=|Object=} shapeConfig - You can define a rgb, hex or name of color to be used to darken the image or you can define
   * an object with this structure: {fill: {color: <color to use to fill>},
   * stroke: {color: color to use to stroke, width: width of the stroke line,
   * lineDash: two dimensions array where first dimension is the width of the dash and second one the space between points}}
   * Keep in mind that this properties are optional, so you can define just fill, stroke or both.
   */
  highlightTexture(masks, shapeConfig) {
    this._sdk.analytics.sendEvent('RevieveAR.highlightTexture', JSON.stringify(masks));
    return shapeConfig === undefined || typeof shapeConfig === 'string'
      ? this.highlightMasksForSection('texture', masks, shapeConfig)
      : this.highlightMasksForSection('texture', masks, null, shapeConfig);
  }

  /**
   * Highlight areas with smoothness detected by CV module.
   * Resulting effects will be applied to div containing the image returned by getResults
   * or to the container specified in constructor.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect
   * @see [getResults]{@link RevieveAR#getResults}
   * @param {String[]} masks - Array of {@link RevieveAR.masks} defining the areas
   * where you want to highlight the concern.
   * Null or empty array will highlight the worst area detected by CV module.
   * @param {String=|Object=} shapeConfig - You can define a rgb, hex or name of color to be used to darken the image or you can define
   * an object with this structure: {fill: {color: <color to use to fill>},
   * stroke: {color: color to use to stroke, width: width of the stroke line,
   * lineDash: two dimensions array where first dimension is the width of the dash and second one the space between points}}
   * Keep in mind that this properties are optional, so you can define just fill, stroke or both.
   */
  highlightSmoothness(masks, shapeConfig) {
    this._sdk.analytics.sendEvent('RevieveAR.highlightSmoothness', JSON.stringify(masks));
    return shapeConfig === undefined || typeof shapeConfig === 'string'
      ? this.highlightMasksForSection('smoothness', masks, shapeConfig)
      : this.highlightMasksForSection('smoothness', masks, null, shapeConfig);
  }

  /**
   * Highlight areas with wrinkles detected by CV module.
   * Resulting effects will be applied to div containing the image returned by getResults
   * or to the container specified in constructor.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect
   * @see [getResults]{@link RevieveAR#getResults}
   * @param {String[]} masks - Array of {@link RevieveAR.masks} defining the areas
   * where you want to highlight the concern.
   * Null or empty array will highlight the worst area detected by CV module.
   * @param {String=|Object=} shapeConfig - You can define a rgb, hex or name of color to be used to darken the image or you can define
   * an object with this structure: {fill: {color: <color to use to fill>},
   * stroke: {color: color to use to stroke, width: width of the stroke line,
   * lineDash: two dimensions array where first dimension is the width of the dash and second one the space between points}}
   * Keep in mind that this properties are optional, so you can define just fill, stroke or both.
   */
  highlightWrinkles(masks, shapeConfig) {
    this._sdk.analytics.sendEvent('RevieveAR.highlightWrinkles', JSON.stringify(masks));
    return shapeConfig === undefined || typeof shapeConfig === 'string'
      ? this.highlightMasksForSection('wrinkles', masks, shapeConfig)
      : this.highlightMasksForSection('wrinkles', masks, null, shapeConfig);
  }

  /**
   * Highlight individual wrinkles detected by CV module.
   * Resulting effects will be applied to div containing the image returned by getResults
   * or to the container specified in constructor.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect
   * @see [getResults]{@link RevieveAR#getResults}
   * @param {Object[]} ranges - Array of ranges that defines
   * the wrinkles visualization.
   * Each object has three keys, minValue (value between 0 and 1),
   * maxValue (value between 0 and 1) and color. Default value is [
      { minValue: 0, maxValue: 0.2, color: 'rgba(44, 192, 205,0.3)' },
      { minValue: 0.2, maxValue: 0.4, color: 'rgba(253, 164, 98, 0.7)' },
      { minValue: 0.4, maxValue: 1, color: 'rgba(205, 44, 66, 1)' },
    ],
   * @param {String[]} Array of {@link RevieveAR.masks} defining the areas
   * where you want to highlight the concern.
   */
  highlightWrinklesIndividually(ranges, masks) {
    this._sdk.analytics.sendEvent('RevieveAR.highlightWrinklesIndividually', ranges);
    return this.setEffectByName(
      'wrinklesIndividually',
      masks,
      0.7,
      null,
      false,
      false,
      ranges || [
        { minValue: 0, maxValue: 0.2, color: 'rgba(44, 192, 205,0.3)' },
        { minValue: 0.2, maxValue: 0.4, color: 'rgba(253, 164, 98, 0.7)' },
        { minValue: 0.4, maxValue: 1, color: 'rgba(205, 44, 66, 1)' },
      ],
    );
  }

  highlightNasolabialFoldsIndividually(ranges, masks) {
    this._sdk.analytics.sendEvent('RevieveAR.highlightNasolabialFoldsIndividually', ranges);
    return this.setEffectByName(
      'nasolabialFoldsIndividually',
      masks,
      0.7,
      null,
      false,
      false,
      ranges || [
        { minValue: 0, maxValue: 0.2, color: 'rgba(44, 192, 205,0.3)' },
        { minValue: 0.2, maxValue: 0.4, color: 'rgba(253, 164, 98, 0.7)' },
        { minValue: 0.4, maxValue: 1, color: 'rgba(205, 44, 66, 1)' },
      ],
    );
  }

  highlightMarionetteLinesIndividually(ranges, masks) {
    this._sdk.analytics.sendEvent('RevieveAR.highlightMarionetteLinesIndividually', ranges);
    return this.setEffectByName(
      'marionetteLinesIndividually',
      masks,
      0.7,
      null,
      false,
      false,
      ranges || [
        { minValue: 0, maxValue: 0.2, color: 'rgba(44, 192, 205,0.3)' },
        { minValue: 0.2, maxValue: 0.4, color: 'rgba(253, 164, 98, 0.7)' },
        { minValue: 0.4, maxValue: 1, color: 'rgba(205, 44, 66, 1)' },
      ],
    );
  }

  highlightUnderEyeLinesIndividually(ranges, masks) {
    this._sdk.analytics.sendEvent('RevieveAR.highlightUnderEyeLinesIndividually', ranges);
    return this.setEffectByName(
      'underEyeLinesIndividually',
      masks,
      0.7,
      null,
      false,
      false,
      ranges || [
        { minValue: 0, maxValue: 0.2, color: 'rgba(44, 192, 205,0.3)' },
        { minValue: 0.2, maxValue: 0.4, color: 'rgba(253, 164, 98, 0.7)' },
        { minValue: 0.4, maxValue: 1, color: 'rgba(205, 44, 66, 1)' },
      ],
    );
  }

  highlightForeheadLinesIndividually(ranges, masks) {
    this._sdk.analytics.sendEvent('RevieveAR.highlightForeheadLinesIndividually', ranges);
    return this.setEffectByName(
      'foreheadLinesIndividually',
      masks,
      0.7,
      null,
      false,
      false,
      ranges || [
        { minValue: 0, maxValue: 0.2, color: 'rgba(44, 192, 205,0.3)' },
        { minValue: 0.2, maxValue: 0.4, color: 'rgba(253, 164, 98, 0.7)' },
        { minValue: 0.4, maxValue: 1, color: 'rgba(205, 44, 66, 1)' },
      ],
    );
  }

  highlightWrinklesForeheadEyesIndividually(ranges, masks) {
    this._sdk.analytics.sendEvent('RevieveAR.highlightWrinklesForeheadEyesIndividually', ranges);
    return this.setEffectByName(
      'wrinklesForeheadEyesIndividually',
      masks,
      0.7,
      null,
      false,
      false,
      ranges || [
        { minValue: 0, maxValue: 0.2, color: 'rgba(44, 192, 205,0.3)' },
        { minValue: 0.2, maxValue: 0.4, color: 'rgba(253, 164, 98, 0.7)' },
        { minValue: 0.4, maxValue: 1, color: 'rgba(205, 44, 66, 1)' },
      ],
    );
  }

  /**
   * Highlight individual hyperpigmentation spots detected by CV module.
   * Resulting effects will be applied to div containing the image returned by getResults
   * or to the container specified in constructor.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect
   * @see [getResults]{@link RevieveAR#getResults}
   * @param {Object[]} ranges - Array of ranges that defines
   * the hyperpigmentation visualization.
   * Each object has three keys, minValue (value between 0 and 10),
   * maxValue (value between 0 and 10) and color. Default value is [
      { minValue: 0, maxValue: 1, color: 'rgba(44, 192, 205,0.3)' },
      { minValue: 1, maxValue: 2, color: 'rgba(253, 164, 98, 0.7)' },
      { minValue: 2, maxValue: 9, color: 'rgba(205, 44, 66, 1)' },
    ]
   * @param {String[]} Array of {@link RevieveAR.masks} defining the areas
   * where you want to highlight the concern.
   */
  highlightHyperpigmentationIndividually(ranges, masks) {
    this._sdk.analytics.sendEvent('RevieveAR.highlightHyperpigmentationIndividually', ranges);
    return this.setEffectByName(
      'hyperpigmentationIndividually',
      masks,
      0.7,
      null,
      false,
      false,
      ranges || [
        { minValue: 0, maxValue: 100, color: 'rgb(44, 192, 205)' },
        { minValue: 100, maxValue: 200, color: 'rgb(253, 164, 98)' },
        { minValue: 200, maxValue: 400, color: 'rgb(205, 44, 66)' },
      ],
    );
  }

  /**
   * Highlight individual freckles detected by CV module.
   * Resulting effects will be applied to div containing the image returned by getResults
   * or to the container specified in constructor.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect
   * @see [getResults]{@link RevieveAR#getResults}
   * @param {Object[]} ranges - Array of ranges that defines
   * the freckles visualization.
   * Each object has three keys, minValue (value between 0 and 10),
   * maxValue (value between 0 and 10) and color. Default value is [
      { minValue: 0, maxValue: 1, color: 'rgba(44, 192, 205,0.3)' },
      { minValue: 1, maxValue: 2, color: 'rgba(253, 164, 98, 0.7)' },
      { minValue: 2, maxValue: 9, color: 'rgba(205, 44, 66, 1)' },
    ]
   * @param {String[]} Array of {@link RevieveAR.masks} defining the areas
   * where you want to highlight the concern.
   */
  highlightFrecklesIndividually(ranges, masks) {
    this._sdk.analytics.sendEvent('RevieveAR.highlightFrecklesIndividually', ranges);
    return this.setEffectByName(
      'frecklesIndividually',
      masks,
      0.7,
      null,
      false,
      false,
      ranges || [
        { minValue: 0, maxValue: 100, color: 'rgb(44, 192, 205)' },
        { minValue: 100, maxValue: 200, color: 'rgb(253, 164, 98)' },
        { minValue: 200, maxValue: 400, color: 'rgb(205, 44, 66)' },
      ],
    );
  }

  /**
   * Highlight individual dark spots detected by CV module.
   * Resulting effects will be applied to div containing the image returned by getResults
   * or to the container specified in constructor.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect
   * @see [getResults]{@link RevieveAR#getResults}
   * @param {Object[]} ranges - Array of ranges that defines
   * the dark spots visualization.
   * Each object has three keys, minValue (value between 0 and 10),
   * maxValue (value between 0 and 10) and color. Default value is [
      { minValue: 0, maxValue: 1, color: 'rgba(44, 192, 205,0.3)' },
      { minValue: 1, maxValue: 2, color: 'rgba(253, 164, 98, 0.7)' },
      { minValue: 2, maxValue: 9, color: 'rgba(205, 44, 66, 1)' },
    ]
   * @param {String[]} Array of {@link RevieveAR.masks} defining the areas
   * where you want to highlight the concern.
   */
  highlightDarkSpotsIndividually(ranges, masks) {
    this._sdk.analytics.sendEvent('RevieveAR.highlightDarkSpotsIndividually', ranges);
    return this.setEffectByName(
      'darkSpotsIndividually',
      masks,
      0.7,
      null,
      false,
      false,
      ranges || [
        { minValue: 0, maxValue: 100, color: 'rgb(44, 192, 205)' },
        { minValue: 100, maxValue: 200, color: 'rgb(253, 164, 98)' },
        { minValue: 200, maxValue: 400, color: 'rgb(205, 44, 66)' },
      ],
    );
  }

  /**
   * Highlight individual acne spots detected by CV module.
   * Resulting effects will be applied to div containing the image returned by getResults
   * or to the container specified in constructor.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect
   * @see [getResults]{@link RevieveAR#getResults}
   * @param {Object[]} ranges - Array of ranges that defines
   * the acne visualization.
   * Each object has three keys, minValue (value between 0 and 2500),
   * maxValue (value between 0 and 2500) and color. Default value is [
      { minValue: 0, maxValue: 80, color: 'rgba(44, 192, 205,0.3)' },
      { minValue: 80, maxValue: 300, color: 'rgba(253, 164, 98, 0.7)' },
      { minValue: 300, maxValue: 2500, color: 'rgba(205, 44, 66, 1)' },
    ]
   * @param {String[]} Array of {@link RevieveAR.masks} defining the areas
   * where you want to highlight the concern.
   */
  highlightAcneIndividually(ranges, masks) {
    this._sdk.analytics.sendEvent('RevieveAR.highlightAcneIndividually', ranges);
    return this.setEffectByName(
      'acneIndividually',
      masks,
      0.7,
      null,
      false,
      false,
      ranges || [
        { minValue: 0, maxValue: 80, color: 'rgba(0, 0, 0,0)' },
        { minValue: 80, maxValue: 300, color: 'rgba(44, 192, 205, 0.3)' },
        { minValue: 300, maxValue: 1000, color: 'rgba(253, 164, 98, 0.7)' },
        { minValue: 1000, maxValue: 2500, color: 'rgba(205, 44, 66, 1)' },
      ],
    );
  }

  /**
   * Highlight skin shine areas detected by CV module.
   * Resulting effects will be applied to div containing the image returned by getResults
   * or to the container specified in constructor.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect
   * @see [getResults]{@link RevieveAR#getResults}
   * @param {Object[]} ranges - Array of ranges that defines
   * the skin shine visualization.
   * Each object has three keys, minValue (value between 0 and 10),
   * maxValue (value between 0 and 10) and color. Default value is [
      { minValue: 0, maxValue: 2, color: 'rgba(255,255,255, 0.5)' },
      { minValue: 2, maxValue: 4, color: 'rgba(44, 192, 205, 0.2)' },
      { minValue: 4, maxValue: 9, color: 'rgb(255, 255, 255)' },
    ]
   */
  highlightSkinShine(ranges) {
    this._sdk.analytics.sendEvent('RevieveAR.highlightSkinShine', ranges);
    return this.setEffectByName(
      'highlightSkinShine',
      null,
      0.7,
      null,
      false,
      false,
      ranges || [
        { minValue: 0, maxValue: 2, color: 'rgba(255,255,255, 0.5)' },
        { minValue: 2, maxValue: 4, color: 'rgba(44, 192, 205, 0.2)' },
        { minValue: 4, maxValue: 9, color: 'rgb(255, 255, 255)' },
      ],
    );
  }

  /**
   * Highlight melasma areas detected by CV module.
   * Resulting effects will be applied to div containing the image returned by getResults
   * or to the container specified in constructor.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect
   * @see [getResults]{@link RevieveAR#getResults}
   * @param {Object[]} ranges - Array of ranges that defines
   * the melasma visualization.
   * Each object has three keys, minValue (value between 0 and 10),
   * maxValue (value between 0 and 10) and color. Default value is [
      { minValue: 0, maxValue: 1.1, color: 'rgba(255, 255, 255, 0.4)' },
      { minValue: 1.1, maxValue: 2.1, color: 'rgba(253, 164, 98, 0.8)' },
      { minValue: 2.1, maxValue: 10, color: 'rgb(44, 192, 205)' },
    ]
   * @param {String[]} Array of {@link RevieveAR.masks} defining the areas
   * where you want to highlight the concern.
   */
  highlightMelasmaIndividually(ranges, masks) {
    this._sdk.analytics.sendEvent('RevieveAR.highlightMelasmaIndividually', ranges);
    return this.setEffectByName(
      'melasmaIndividually',
      masks,
      0.7,
      null,
      false,
      false,
      ranges || [
        { minValue: 0, maxValue: 1.1, color: 'rgba(255, 255, 255, 0.4)' },
        { minValue: 1.1, maxValue: 2.1, color: 'rgba(253, 164, 98, 0.8)' },
        { minValue: 2.1, maxValue: 10, color: 'rgb(44, 192, 205)' },
      ],
    );
  }

  /**
   * Highlight uneven skin tone areas detected by CV module.
   * Resulting effects will be applied to div containing the image returned by getResults
   * or to the container specified in constructor.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect
   * @see [getResults]{@link RevieveAR#getResults}
   * @param {Object[]} ranges - Array of ranges that defines
   * the uneven skin tone visualization.
   * Each object has three keys, minValue (value between 0 and 10),
   * maxValue (value between 0 and 10) and color. Default value is [
      { minValue: 0, maxValue: 1.1, color: 'rgba(255, 255, 255, 0.4)' },
      { minValue: 1.1, maxValue: 2.1, color: 'rgba(253, 164, 98, 0.8)' },
      { minValue: 2.1, maxValue: 10, color: 'rgb(44, 192, 205)' },
    ]
   * @param {String[]} Array of {@link RevieveAR.masks} defining the areas
   * where you want to highlight the concern.
   */
  highlightUnevenSkinToneIndividually(ranges, masks) {
    this._sdk.analytics.sendEvent('RevieveAR.highlightUnevenSkinToneIndividually', ranges);
    return this.setEffectByName(
      'unevenSkinToneIndividually',
      masks,
      0.7,
      null,
      false,
      false,
      ranges || [
        { minValue: 0, maxValue: 1.1, color: 'rgba(255, 255, 255, 0.4)' },
        { minValue: 1.1, maxValue: 2.1, color: 'rgba(253, 164, 98, 0.8)' },
        { minValue: 2.1, maxValue: 10, color: 'rgb(44, 192, 205)' },
      ],
    );
  }

  /**
   * Highlight areas with redness detected by CV module.
   * Resulting effects will be applied to div containing the image returned by getResults
   * or to the container specified in constructor.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect
   * @see [getResults]{@link RevieveAR#getResults}
   * @param {Object[]} ranges - Array of ranges that defines
   * the uneven skin tone visualization.
   * Each object has three keys, minValue (value between 0 and 10),
   * maxValue (value between 0 and 10) and color. Default value is [
        { minValue: 0, maxValue: 1.1, color: 'rgba(255, 255, 255, 0.4)' },
        { minValue: 1.1, maxValue: 2.1, color: 'rgba(253, 164, 98, 0.8)' },
        { minValue: 2.1, maxValue: 10, color: 'rgb(44, 192, 205)' },
      ]
   * @param {String[]} Array of {@link RevieveAR.masks} defining the areas
   * where you want to highlight the concern.
   */
  highlightRednessIndividually(ranges, masks) {
    this._sdk.analytics.sendEvent('RevieveAR.highlightRednessIndividually', ranges);
    return this.setEffectByName(
      'rednessIndividually',
      masks,
      0.7,
      null,
      false,
      false,
      ranges || [
        { minValue: 0, maxValue: 1.1, color: 'rgba(255, 255, 255, 0.4)' },
        { minValue: 1.1, maxValue: 2.1, color: 'rgba(253, 164, 98, 0.8)' },
        { minValue: 2.1, maxValue: 10, color: 'rgb(44, 192, 205)' },
      ],
    );
  }

  /**
   * Highlight areas with texture detected by CV module.
   * Resulting effects will be applied to div containing the image returned by getResults
   * or to the container specified in constructor.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect
   * @see [getResults]{@link RevieveAR#getResults}
   * @param {Object[]} ranges - Array of ranges that defines
   * the uneven skin tone visualization.
   * Each object has three keys, minValue (value between 0 and 10),
   * maxValue (value between 0 and 10) and color. Default value is [
        { minValue: 0, maxValue: 1.1, color: 'rgba(255, 255, 255, 0.4)' },
        { minValue: 1.1, maxValue: 2.1, color: 'rgba(253, 164, 98, 0.8)' },
        { minValue: 2.1, maxValue: 10, color: 'rgb(44, 192, 205)' },
      ]
   * @param {String[]} Array of {@link RevieveAR.masks} defining the areas
   * where you want to highlight the concern.
   */
  highlightTextureIndividually(ranges, masks) {
    this._sdk.analytics.sendEvent('RevieveAR.highlightTextureIndividually', ranges);
    return this.setEffectByName(
      'textureIndividually',
      masks,
      0.7,
      null,
      false,
      false,
      ranges || [
        { minValue: 0, maxValue: 1.1, color: 'rgba(255, 255, 255, 0.4)' },
        { minValue: 1.1, maxValue: 2.1, color: 'rgba(253, 164, 98, 0.8)' },
        { minValue: 2.1, maxValue: 10, color: 'rgb(44, 192, 205)' },
      ],
    );
  }

  /**
   * Highlight areas with pore dilation detected by CV module.
   * Resulting effects will be applied to div containing the image returned by getResults
   * or to the container specified in constructor.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect
   * @see [getResults]{@link RevieveAR#getResults}
   * @param {Object[]} ranges - Array of ranges that defines
   * the uneven skin tone visualization.
   * Each object has three keys, minValue (value between 0 and 10),
   * maxValue (value between 0 and 10) and color. Default value is [
        { minValue: 0, maxValue: 1.1, color: 'rgba(255, 255, 255, 0.4)' },
        { minValue: 1.1, maxValue: 2.1, color: 'rgba(253, 164, 98, 0.8)' },
        { minValue: 2.1, maxValue: 10, color: 'rgb(44, 192, 205)' },
      ]
   * @param {String[]} Array of {@link RevieveAR.masks} defining the areas
   * where you want to highlight the concern.
   */
  highlightPoreDilationIndividually(ranges, masks) {
    this._sdk.analytics.sendEvent('RevieveAR.highlightPoreDialtionIndividually', ranges);
    return this.setEffectByName(
      'poreDilationIndividually',
      masks,
      0.7,
      null,
      false,
      false,
      ranges || [{ minValue: 0, maxValue: 1000, color: 'rgba(255, 0, 0, 1)' }],
    );
  }

  /**
   * Highlight areas with skin sagging detected by CV module.
   * Resulting effects will be applied to div containing the image returned by getResults
   * or to the container specified in constructor.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect
   * @see [getResults]{@link RevieveAR#getResults}
   * @param {Object[]} ranges - Array of ranges that defines
   * the skin shine visualization.
   * Each object has three keys, minValue (value between 0 and 10),
   * maxValue (value between 0 and 10) and color. Default value is [
        { minValue: 0, maxValue: 0.33, color: 'rgba(255,255,255, 0.3)' },
        { minValue: 0.33, maxValue: 0.66, color: 'rgba(44, 192, 205, 0.3)' },
        { minValue: 0.66, maxValue: 1, color: 'rgb(2205, 44, 66, 0.3)' },
      ]
   */
  highlightSkinSaggingIndividually(ranges) {
    this._sdk.analytics.sendEvent('RevieveAR.highlightSkinSaggingIndividually', ranges);
    return this.setEffectByName(
      'skinSaggingIndividually',
      null,
      0.7,
      null,
      false,
      false,
      ranges || [
        { minValue: 0, maxValue: 0.33, color: 'rgba(255,255,255, 0.3)' },
        { minValue: 0.33, maxValue: 0.66, color: 'rgba(44, 192, 205, 0.3)' },
        { minValue: 0.66, maxValue: 1, color: 'rgba(205, 44, 66, 0.3)' },
      ],
    );
  }

  /**
   * Highlight hyperpigmentation values in areas detected by CV module.
   * Resulting effects will be applied to div containing the image returned by getResults
   * or to the container specified in constructor.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect
   * @see [getResults]{@link RevieveAR#getResults}
   * @param {Object[]} ranges - Array of ranges that defines
   * the hyperpigmentation visualization.
   * Each object has three keys, minValue (value between 0 and 1),
   * maxValue (value between 0 and 1) and color. Default value is [
      { minValue: 0, maxValue: 0.3, color: 'rgb(44, 192, 205)' },
      { minValue: 0.3, maxValue: 0.6, color: 'rgb(253, 164, 98)' },
      { minValue: 0.6, maxValue: 1, color: 'rgb(205, 44, 66)' },
    ]
   * @param {String[]} Array of {@link RevieveAR.masks} defining the areas
   * where you want to highlight the concern.
   */
  highlightHyperpigmentationAreaValues(ranges, masks) {
    this._sdk.analytics.sendEvent('RevieveAR.highlightHyperpigmentationAreaValues', ranges);
    return this.setEffectByName(
      'hyperpigmentationAreaValues',
      masks,
      0.7,
      null,
      false,
      false,
      ranges || [
        { minValue: 0, maxValue: 0.33, color: 'rgb(44, 192, 205)' },
        { minValue: 0.33, maxValue: 0.66, color: 'rgb(253, 164, 98)' },
        { minValue: 0.66, maxValue: 1, color: 'rgb(205, 44, 66)' },
      ],
    );
  }

  /**
   * Highlight melasma values in areas detected by CV module.
   * Resulting effects will be applied to div containing the image returned by getResults
   * or to the container specified in constructor.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect
   * @see [getResults]{@link RevieveAR#getResults}
   * @param {Object[]} ranges - Array of ranges that defines
   * the melasma visualization.
   * Each object has three keys, minValue (value between 0 and 1),
   * maxValue (value between 0 and 1) and color. Default value is [
      { minValue: 0, maxValue: 0.3, color: 'rgb(44, 192, 205)' },
      { minValue: 0.3, maxValue: 0.6, color: 'rgb(253, 164, 98)' },
      { minValue: 0.6, maxValue: 1, color: 'rgb(205, 44, 66)' },
    ]
   * @param {String[]} Array of {@link RevieveAR.masks} defining the areas
   * where you want to highlight the concern.
   */
  highlightMelasmaAreaValues(ranges, masks) {
    this._sdk.analytics.sendEvent('RevieveAR.highlightMelasmaAreaValues', ranges);
    return this.setEffectByName(
      'melasmaAreaValues',
      masks,
      0.7,
      null,
      false,
      false,
      ranges || [
        { minValue: 0, maxValue: 0.33, color: 'rgb(44, 192, 205)' },
        { minValue: 0.33, maxValue: 0.66, color: 'rgb(253, 164, 98)' },
        { minValue: 0.66, maxValue: 1, color: 'rgb(205, 44, 66)' },
      ],
    );
  }

  /**
   * Highlight freckles values in areas detected by CV module.
   * Resulting effects will be applied to div containing the image returned by getResults
   * or to the container specified in constructor.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect
   * @see [getResults]{@link RevieveAR#getResults}
   * @param {Object[]} ranges - Array of ranges that defines
   * the freckles visualization.
   * Each object has three keys, minValue (value between 0 and 1),
   * maxValue (value between 0 and 1) and color. Default value is [
      { minValue: 0, maxValue: 0.3, color: 'rgb(44, 192, 205)' },
      { minValue: 0.3, maxValue: 0.6, color: 'rgb(253, 164, 98)' },
      { minValue: 0.6, maxValue: 1, color: 'rgb(205, 44, 66)' },
    ]
   * @param {String[]} Array of {@link RevieveAR.masks} defining the areas
   * where you want to highlight the concern.
   */
  highlightFrecklesAreaValues(ranges, masks) {
    this._sdk.analytics.sendEvent('RevieveAR.highlightFrecklesAreaValues', ranges);
    return this.setEffectByName(
      'frecklesAreaValues',
      masks,
      0.7,
      null,
      false,
      false,
      ranges || [
        { minValue: 0, maxValue: 0.33, color: 'rgb(44, 192, 205)' },
        { minValue: 0.33, maxValue: 0.66, color: 'rgb(253, 164, 98)' },
        { minValue: 0.66, maxValue: 1, color: 'rgb(205, 44, 66)' },
      ],
    );
  }

  /**
   * Highlight dark spots values in areas detected by CV module.
   * Resulting effects will be applied to div containing the image returned by getResults
   * or to the container specified in constructor.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect
   * @see [getResults]{@link RevieveAR#getResults}
   * @param {Object[]} ranges - Array of ranges that defines
   * the dark spots visualization.
   * Each object has three keys, minValue (value between 0 and 1),
   * maxValue (value between 0 and 1) and color. Default value is [
      { minValue: 0, maxValue: 0.3, color: 'rgb(44, 192, 205)' },
      { minValue: 0.3, maxValue: 0.6, color: 'rgb(253, 164, 98)' },
      { minValue: 0.6, maxValue: 1, color: 'rgb(205, 44, 66)' },
    ]
   * @param {String[]} Array of {@link RevieveAR.masks} defining the areas
   * where you want to highlight the concern.
   */
  highlightDarkSpotsAreaValues(ranges, masks) {
    this._sdk.analytics.sendEvent('RevieveAR.highlightDarkSpotsAreaValues', ranges);
    return this.setEffectByName(
      'darkSpotsAreaValues',
      masks,
      0.7,
      null,
      false,
      false,
      ranges || [
        { minValue: 0, maxValue: 0.33, color: 'rgb(44, 192, 205)' },
        { minValue: 0.33, maxValue: 0.66, color: 'rgb(253, 164, 98)' },
        { minValue: 0.66, maxValue: 1, color: 'rgb(205, 44, 66)' },
      ],
    );
  }

  /**
   * Highlight wrinkles values in areas detected by CV module.
   * Resulting effects will be applied to div containing the image returned by getResults
   * or to the container specified in constructor.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect
   * @see [getResults]{@link RevieveAR#getResults}
   * @param {Object[]} ranges - Array of ranges that defines
   * the wrinkles visualization.
   * Each object has three keys, minValue (value between 0 and 1),
   * maxValue (value between 0 and 1) and color. Default value is [
      { minValue: 0, maxValue: 0.3, color: 'rgb(44, 192, 205)' },
      { minValue: 0.3, maxValue: 0.6, color: 'rgb(253, 164, 98)' },
      { minValue: 0.6, maxValue: 1, color: 'rgb(205, 44, 66)' },
    ]
   * @param {String[]} Array of {@link RevieveAR.masks} defining the areas
   * where you want to highlight the concern.
   */
  highlightWrinklesAreaValues(ranges, masks) {
    this._sdk.analytics.sendEvent('RevieveAR.highlightWrinklesAreaValues', ranges);
    return this.setEffectByName(
      'wrinklesAreaValues',
      masks,
      0.7,
      null,
      false,
      false,
      ranges || [
        { minValue: 0, maxValue: 0.33, color: 'rgb(44, 192, 205)' },
        { minValue: 0.33, maxValue: 0.66, color: 'rgb(253, 164, 98)' },
        { minValue: 0.66, maxValue: 1, color: 'rgb(205, 44, 66)' },
      ],
    );
  }

  /**
   * Highlight redness values in areas detected by CV module.
   * Resulting effects will be applied to div containing the image returned by getResults
   * or to the container specified in constructor.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect
   * @see [getResults]{@link RevieveAR#getResults}
   * @param {Object[]} ranges - Array of ranges that defines
   * the redness visualization.
   * Each object has three keys, minValue (value between 0 and 1),
   * maxValue (value between 0 and 1) and color. Default value is [
      { minValue: 0, maxValue: 0.3, color: 'rgb(44, 192, 205)' },
      { minValue: 0.3, maxValue: 0.6, color: 'rgb(253, 164, 98)' },
      { minValue: 0.6, maxValue: 1, color: 'rgb(205, 44, 66)' },
    ]
   * @param {String[]} Array of {@link RevieveAR.masks} defining the areas
   * where you want to highlight the concern.
   */
  highlightRednessAreaValues(ranges, masks) {
    this._sdk.analytics.sendEvent('RevieveAR.highlightRednessAreaValues', ranges);
    return this.setEffectByName(
      'rednessAreaValues',
      masks,
      0.7,
      null,
      false,
      false,
      ranges || [
        { minValue: 0, maxValue: 0.33, color: 'rgb(44, 192, 205)' },
        { minValue: 0.33, maxValue: 0.66, color: 'rgb(253, 164, 98)' },
        { minValue: 0.66, maxValue: 1, color: 'rgb(205, 44, 66)' },
      ],
    );
  }

  /**
   * Highlight texture values in areas detected by CV module.
   * Resulting effects will be applied to div containing the image returned by getResults
   * or to the container specified in constructor.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect
   * @see [getResults]{@link RevieveAR#getResults}
   * @param {Object[]} ranges - Array of ranges that defines
   * the texture visualization.
   * Each object has three keys, minValue (value between 0 and 1),
   * maxValue (value between 0 and 1) and color. Default value is [
      { minValue: 0, maxValue: 0.3, color: 'rgb(44, 192, 205)' },
      { minValue: 0.3, maxValue: 0.6, color: 'rgb(253, 164, 98)' },
      { minValue: 0.6, maxValue: 1, color: 'rgb(205, 44, 66)' },
    ]
   * @param {String[]} Array of {@link RevieveAR.masks} defining the areas
   * where you want to highlight the concern.
   */
  highlightTextureAreaValues(ranges, masks) {
    this._sdk.analytics.sendEvent('RevieveAR.highlightTextureAreaValues', ranges);
    return this.setEffectByName(
      'textureAreaValues',
      masks,
      0.7,
      null,
      false,
      false,
      ranges || [
        { minValue: 0, maxValue: 0.33, color: 'rgb(44, 192, 205)' },
        { minValue: 0.33, maxValue: 0.66, color: 'rgb(253, 164, 98)' },
        { minValue: 0.66, maxValue: 1, color: 'rgb(205, 44, 66)' },
      ],
    );
  }

  /**
   * Highlight acne values in areas detected by CV module.
   * Resulting effects will be applied to div containing the image returned by getResults
   * or to the container specified in constructor.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect
   * @see [getResults]{@link RevieveAR#getResults}
   * @param {Object[]} ranges - Array of ranges that defines
   * the acne visualization.
   * Each object has three keys, minValue (value between 0 and 1),
   * maxValue (value between 0 and 1) and color. Default value is [
      { minValue: 0, maxValue: 0.3, color: 'rgb(44, 192, 205)' },
      { minValue: 0.3, maxValue: 0.6, color: 'rgb(253, 164, 98)' },
      { minValue: 0.6, maxValue: 1, color: 'rgb(205, 44, 66)' },
    ]
   * @param {String[]} Array of {@link RevieveAR.masks} defining the areas
   * where you want to highlight the concern.
   */
  highlightAcneAreaValues(ranges, masks) {
    this._sdk.analytics.sendEvent('RevieveAR.highlightAcneAreaValues', ranges);
    return this.setEffectByName(
      'acneAreaValues',
      masks,
      0.7,
      null,
      false,
      false,
      ranges || [
        { minValue: 0, maxValue: 0.33, color: 'rgb(44, 192, 205)' },
        { minValue: 0.33, maxValue: 0.66, color: 'rgb(253, 164, 98)' },
        { minValue: 0.66, maxValue: 1, color: 'rgb(205, 44, 66)' },
      ],
    );
  }

  /**
   * Highlight smoothness values in areas detected by CV module.
   * Resulting effects will be applied to div containing the image returned by getResults
   * or to the container specified in constructor.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect
   * @see [getResults]{@link RevieveAR#getResults}
   * @param {Object[]} ranges - Array of ranges that defines
   * the smoothness visualization.
   * Each object has three keys, minValue (value between 0 and 1),
   * maxValue (value between 0 and 1) and color. Default value is [
      { minValue: 0, maxValue: 0.3, color: 'rgb(44, 192, 205)' },
      { minValue: 0.3, maxValue: 0.6, color: 'rgb(253, 164, 98)' },
      { minValue: 0.6, maxValue: 1, color: 'rgb(205, 44, 66)' },
    ]
   * @param {String[]} Array of {@link RevieveAR.masks} defining the areas
   * where you want to highlight the concern.
   */
  highlightSmoothnessAreaValues(ranges, masks) {
    this._sdk.analytics.sendEvent('RevieveAR.highlightSmoothnessAreaValues', ranges);
    return this.setEffectByName(
      'smoothnessAreaValues',
      masks,
      0.7,
      null,
      false,
      false,
      ranges || [
        { minValue: 0, maxValue: 0.33, color: 'rgb(44, 192, 205)' },
        { minValue: 0.33, maxValue: 0.66, color: 'rgb(253, 164, 98)' },
        { minValue: 0.66, maxValue: 1, color: 'rgb(205, 44, 66)' },
      ],
    );
  }

  /**
   * Highlight unevenSkinTone values in areas detected by CV module.
   * Resulting effects will be applied to div containing the image returned by getResults
   * or to the container specified in constructor.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect
   * @see [getResults]{@link RevieveAR#getResults}
   * @param {Object[]} ranges - Array of ranges that defines
   * the unevenSkinTone visualization.
   * Each object has three keys, minValue (value between 0 and 1),
   * maxValue (value between 0 and 1) and color. Default value is [
      { minValue: 0, maxValue: 0.3, color: 'rgb(44, 192, 205)' },
      { minValue: 0.3, maxValue: 0.6, color: 'rgb(253, 164, 98)' },
      { minValue: 0.6, maxValue: 1, color: 'rgb(205, 44, 66)' },
    ]
   * @param {String[]} Array of {@link RevieveAR.masks} defining the areas
   * where you want to highlight the concern.
   */
  highlightUnevenSkinToneAreaValues(ranges, masks) {
    this._sdk.analytics.sendEvent('RevieveAR.highlightUnevenSkinToneAreaValues', ranges);
    return this.setEffectByName(
      'unevenSkinToneAreaValues',
      masks,
      0.7,
      null,
      false,
      false,
      ranges || [
        { minValue: 0, maxValue: 0.33, color: 'rgb(44, 192, 205)' },
        { minValue: 0.33, maxValue: 0.66, color: 'rgb(253, 164, 98)' },
        { minValue: 0.66, maxValue: 1, color: 'rgb(205, 44, 66)' },
      ],
    );
  }

  /**
   * Highlight eyebags values in areas detected by CV module.
   * Resulting effects will be applied to div containing the image returned by getResults
   * or to the container specified in constructor.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect
   * @see [getResults]{@link RevieveAR#getResults}
   * @param {Object[]} ranges - Array of ranges that defines
   * the eyebags visualization.
   * Each object has three keys, minValue (value between 0 and 1),
   * maxValue (value between 0 and 1) and color. Default value is [
      { minValue: 0, maxValue: 0.3, color: 'rgb(44, 192, 205)' },
      { minValue: 0.3, maxValue: 0.6, color: 'rgb(253, 164, 98)' },
      { minValue: 0.6, maxValue: 1, color: 'rgb(205, 44, 66)' },
    ]
   * @param {String[]} Array of {@link RevieveAR.masks} defining the areas
   * where you want to highlight the concern.
   */
  highlightEyebagsAreaValues(ranges, masks) {
    this._sdk.analytics.sendEvent('RevieveAR.highlightEyebagsAreaValues', ranges);
    return this.setEffectByName(
      'eyebagsAreaValues',
      masks,
      0.7,
      null,
      false,
      false,
      ranges || [
        { minValue: 0, maxValue: 0.33, color: 'rgb(44, 192, 205)' },
        { minValue: 0.33, maxValue: 0.66, color: 'rgb(253, 164, 98)' },
        { minValue: 0.66, maxValue: 1, color: 'rgb(205, 44, 66)' },
      ],
    );
  }

  /**
   * Highlight dark circles values in areas detected by CV module.
   * Resulting effects will be applied to div containing the image returned by getResults
   * or to the container specified in constructor.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect
   * @see [getResults]{@link RevieveAR#getResults}
   * @param {Object[]} ranges - Array of ranges that defines
   * the dark circles visualization.
   * Each object has three keys, minValue (value between 0 and 1),
   * maxValue (value between 0 and 1) and color. Default value is [
      { minValue: 0, maxValue: 0.3, color: 'rgb(44, 192, 205)' },
      { minValue: 0.3, maxValue: 0.6, color: 'rgb(253, 164, 98)' },
      { minValue: 0.6, maxValue: 1, color: 'rgb(205, 44, 66)' },
    ]
   * @param {String[]} Array of {@link RevieveAR.masks} defining the areas
   * where you want to highlight the concern.
   */
  highlightDarkcirclesAreaValues(ranges, masks) {
    this._sdk.analytics.sendEvent('RevieveAR.highlightDarkcirclesAreaValues', ranges);
    return this.setEffectByName(
      'darkcirclesAreaValues',
      masks,
      0.7,
      null,
      false,
      false,
      ranges || [
        { minValue: 0, maxValue: 0.33, color: 'rgb(44, 192, 205)' },
        { minValue: 0.33, maxValue: 0.66, color: 'rgb(253, 164, 98)' },
        { minValue: 0.66, maxValue: 1, color: 'rgb(205, 44, 66)' },
      ],
    );
  }

  /**
   * Highlight the face shape detected by CV module.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect.
   * @param {String=} color - color to be used to stoke the face shape
   */
  highlightFaceShape(color) {
    this._sdk.analytics.sendEvent('RevieveAR.highlightFaceShape');
    return this.setEffectByName('faceShape', ['Skin'], 1, color, false, true, null, {
      stroke: { color: color || '#3cadb2', width: 4, lineDash: [4, 8] },
    });
  }

  /**
   * Highlight the eye color information.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect.
   */
  highlightEyeColor() {
    this._sdk.analytics.sendEvent('RevieveAR.highlightEyeColor');
    // we only want to zoom the eyes area -> we set the intensity to 0
    return new Promise((resolve) => {
      this.setEffectByName('leftEye', ['Skin'], 0, null, false, true).then((animationStyle) => {
        let eyeColor = this._sdk.getConfiguration().eye_color_ui;
        if (!eyeColor) {
          let findings = this._sdk.CV.getFindings();
          eyeColor = findings.eyeColor.value;
        }
        if (eyeColor) {
          eyeColor = eyeColorMapping[eyeColor.toLowerCase()];
          this.renderHighlightInformation('leftEye', eyeColor, 'center');
        }
        resolve(animationStyle);
      });
    });
  }

  /**
   * Highlight the eyes area.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect.
   */
  highlightEyesArea() {
    this._sdk.analytics.sendEvent('RevieveAR.highlightEyesShape');
    // we only want to zoom the eyes area -> we set the intensity to 0
    return this.setEffectByName('leftEye', ['Skin'], 0, null, false, true);
  }

  /**
   * Highlight cheek area.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect.
   */
  highlightCheekArea() {
    this._sdk.analytics.sendEvent('RevieveAR.highlightCheekArea');
    // we only want to zoom the cheek area -> we set the intensity to 0
    return this.setEffectByName('leftCheek', ['Skin'], 0, null, false, true);
  }

  /**
   * Highlight skin undertone information.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect.
   */
  highlightSkinUndertone() {
    this._sdk.analytics.sendEvent('RevieveAR.highlightSkinUndertone');
    // we only want to zoom the cheek area -> we set the intensity to 0
    return new Promise((resolve) => {
      this.setEffectByName('leftCheek', ['Skin'], 0, null, false, true).then((animationStyle) => {
        let findings = this._sdk.CV.getFindings();
        let skinUndertoneColor = findings.skinUndertone.value;
        if (skinUndertoneColor) {
          skinUndertoneColor = skinUndertoneMapping[skinUndertoneColor.toLowerCase()];
          this.renderHighlightInformation('leftCheek', skinUndertoneColor);
        }
        resolve(animationStyle);
      });
    });
  }

  /**
   * Highlight skin foundation matching information.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect.
   */
  highlightSkinFoundation() {
    this._sdk.analytics.sendEvent('RevieveAR.highlightSkinFoundation');
    // we only want to zoom the cheek area -> we set the intensity to 0
    // return this.setEffectByName('leftCheek', ['Skin'], 0, null, false, true);
    return new Promise((resolve) => {
      this.setEffectByName('leftCheek', ['Skin'], 0, null, false, true).then((animationStyle) => {
        let skintoneColor = this._sdk.getConfiguration().skintone_color_cv;
        if (skintoneColor) {
          this.renderHighlightInformation('leftCheek', skintoneColor);
        }
        resolve(animationStyle);
      });
    });
  }

  renderHighlightInformation(area, eyeColor) {
    let infoCanvas = createNewCanvas('highlightInfo', this._state.image.width, this._state.image.height);
    infoCanvas.style.transform = 'scale(' + this._state.image.ratio + ')';
    this._state.layers.highlightInfo = infoCanvas;
    this._resultDiv.appendChild(infoCanvas);
    let containerObject = this.getContainer();
    while (containerObject.firstChild) {
      containerObject.removeChild(containerObject.firstChild);
    }
    containerObject.appendChild(this._resultDiv);
    const { bounding_box: boundingBox } = this._sdk.CV.getFaceArea();
    const faceUnitX = parseInt((boundingBox[2] - boundingBox[0]) / 10, 10);
    const faceUnitY = parseInt((boundingBox[3] - boundingBox[1]) / 10, 10);
    let coordX = boundingBox[0] + faceUnitX;
    let coordY = boundingBox[1] + faceUnitY * 4;
    if (area === 'leftCheek') {
      coordX = boundingBox[0] + faceUnitX * 2;
      coordY = boundingBox[1] + faceUnitY * 7;
    }
    drawCircle(
      infoCanvas,
      coordX,
      coordY,
      this._state.image.width < this._state.image.height
        ? this._state.image.width * 0.04
        : this._state.image.height * 0.03,
      eyeColor,
      'white',
      true,
    );
  }

  /**
   * Method to remove all the highlights configured in picture
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect
   */
  dehighlightAll() {
    const specialHighlightMethods = [
      'wrinklesIndividually',
      'wrinklesForeheadEyesIndividually',
      'nasolabialFoldsIndividually',
      'marionetteLinesIndividually',
      'underEyeLinesIndividually',
      'foreheadLinesIndividually',
      'hyperpigmentationIndividually',
      'frecklesIndividually',
      'darkSpotsIndividually',
      'acneIndividually',
      'highlightSkinShine',
      'melasmaIndividually',
      'unevenSkinToneIndividually',
      'hyperpigmentationAreaValues',
      'melasmaAreaValues',
      'frecklesAreaValues',
      'darkSpotsAreaValues',
      'wrinklesAreaValues',
      'rednessAreaValues',
      'textureAreaValues',
      'acneAreaValues',
      'smoothnessAreaValues',
      'unevenSkinToneAreaValues',
      'eyebagsAreaValues',
      'darkcirclesAreaValues',
      'rednessIndividually',
      'textureIndividually',
      'skinSaggingIndividually',
      'poreDilationIndividually',
    ];
    this._state.effectHistory = this._state.effectHistory.filter(
      (effect) => !effect.highlight && !effect.highlightShape && !specialHighlightMethods.includes(effect.effectName),
    );

    return new Promise((resolve) => {
      const nullStyle = {
        in: {},
        out: {
          transform: 'rotate(0deg) scale(1) translate3d(0px, 0px, 0px)',
          transformOrigin: 'center center',
          transition: 'all 0.8s ease-in-out',
        },
      };

      if (!this._resultDiv) {
        resolve(nullStyle);
        return;
      }
      specialHighlightMethods.forEach((highlight) => {
        if (this._state.layers[highlight]) {
          for (let divChildren of this._resultDiv.children) {
            if (divChildren.id === highlight) {
              this._resultDiv.removeChild(divChildren);
            }
          }
        }
        delete this._state.layers[highlight];
      });
      let canvas = this._state.layers.highlight || this._state.layers.highlightInfo;
      if (canvas) {
        for (let divChildren of this._resultDiv.children) {
          if (divChildren.id === 'highlight' || divChildren.id === 'highlightInfo') {
            this._resultDiv.removeChild(divChildren);
          }
        }
        let containerObject = this.getContainer();
        while (containerObject.firstChild) {
          containerObject.removeChild(containerObject.firstChild);
        }
        containerObject.appendChild(this._resultDiv);
        let animationStyle = getAnimationStyle(
          canvas,
          this._sdk.CV.getFaceArea(),
          this._state.image.ratio,
          'highlight',
          this._state.scaleFactor,
        );
        if (this._state.layers.highlight) delete this._state.layers.highlight;
        if (this._state.layers.highlightInfo) delete this._state.layers.highlightInfo;
        resolve(animationStyle);
      } else {
        resolve(nullStyle);
      }
    });
  }

  highlightMasksForSection(section, masks, fillColor, shapeConfig) {
    let cvResults = this._sdk.CV.getResults();
    if (!cvResults) {
      this._state.error = true;
      let message = 'RevieveAR needs an image analyzed by CV module before';
      console.error(message);
    }
    let sectionToFind = section;
    if (section === 'eyebags' || section === 'darkcircles') {
      sectionToFind = 'eyes';
    }
    if (!masks || masks.length === 0) {
      for (const cvResult of cvResults) {
        if (cvResult.description === sectionToFind) {
          let maxValue = 0;
          for (const measurement of cvResult.measurement_locations) {
            if (measurement.value > maxValue && measurement.mask_shapes) {
              maxValue = measurement.value;
              masks = [];
              masks.push(measurement.description);
            }
          }
          break;
        }
      }
    }
    return this.setEffectByName(section, masks, 0.7, fillColor, true, true, null, shapeConfig);
  }

  /**
   * Method to determine if a product could be visualize by AR module
   * @return {Boolean}
   * @param {Object} product - JSON object with information of a product.
   * @see [getRecommendedProducts]{@link RevievePR#getRecommendedProducts}
   */
  canVisualizeProduct(product) {
    if (!product) {
      return false;
    }
    if (product.custom_data && product.custom_data.custom_visualization) {
      return true;
    }
    if (this.getColorInfoFromProduct(product)) {
      return true;
    }
    return false;
  }

  /**
   * Method to apply the simulated effect of applying a product in picture
   * @returns {Promise}
   * @param {Object} product - JSON object with information of a product.
   * @param {Float=} strength - Strength of product effect.
   * See {@link RevieveAR.defaultStrength}
   * @see [getRecommendedProducts]{@link RevievePR#getRecommendedProducts}
   */
  visualizeProduct(product, strength) {
    this._sdk.analytics.sendEvent('RevieveAR.visualizeProduct', product.id);
    if (!this.canVisualizeProduct(product)) {
      return null;
    }
    if (product.custom_data && product.custom_data.custom_visualization) {
      const visualizationInfo = product.custom_data.custom_visualization;
      if (strength === 0) {
        const methodParameters = [0, ...visualizationInfo.parameters.slice(1)];
        return this[visualizationInfo.method](methodParameters);
      } else {
        return this[visualizationInfo.method](...visualizationInfo.parameters);
      }
    } else {
      let productVisualizationInfo = productVisualization.find(
        (productV) => productV.category === product.category.toLowerCase().replace(' ', ''),
      );
      let colorInfo = this.getColorInfoFromProduct(product);
      return this[productVisualizationInfo.method](
        strength === undefined ? productVisualizationInfo.strength : strength,
        colorInfo,
      );
    }
  }

  /**
   * Help method to get color info of a product to AR module interactions
   * @param {Object} product - JSON object with information of a product.
   * @see [getRecommendedProducts]{@link RevievePR#getRecommendedProducts}
   */
  getColorInfoFromProduct(product) {
    if (!product.category || !product.filter_match) {
      return null;
    }
    let productVisualizationInfo = productVisualization.find(
      (productV) => productV.category === product.category.toLowerCase().replace(' ', ''),
    );
    if (!productVisualizationInfo) {
      return null;
    }
    let colorInfo = product.filter_match.find(
      (filterMatch) => filterMatch.indexOf(productVisualizationInfo.colorExpression) === 0,
    );
    if (!colorInfo) {
      return null;
    }
    colorInfo = colorInfo.replace(productVisualizationInfo.colorExpression, '');
    colorInfo = colorInfo.replace('#', '');
    colorInfo = colorInfo.replace('0x', '');
    return '#' + colorInfo;
  }

  /**
   * Help method to get default stregth info of a product to AR module interactions
   * @param {Object} product - JSON object with information of a product.
   * @see [getRecommendedProducts]{@link RevievePR#getRecommendedProducts}
   */
  getDefaultStrengthFromProduct(product) {
    let productVisualizationInfo = productVisualization.find(
      (productV) => productV.category === product.category.toLowerCase().replace(' ', ''),
    );
    if (!productVisualizationInfo) {
      return null;
    }
    return productVisualizationInfo.strength;
  }

  async initializeImage() {
    const dimensions = await getImageDimensions(this._sdk.CV.getImage());
    this._state.image.width = dimensions.width;
    this._state.image.height = dimensions.height;
    this._state.finalDimensions = null;
    if (this._state.container) {
      this._state.finalDimensions = calculateAspectRatioFit(
        dimensions.width,
        dimensions.height,
        this._state.container.offsetWidth,
        this._state.container.offsetHeight,
        this._state.orientation,
      );
      if (this._state.finalDimensions) {
        this._state.image.ratio = this._state.finalDimensions.ratio;
      }
    }
  }

  /**
   * Please don't use this method directly. You have the general effect methods to work with them.
   * Set an effect determined by parameter effect name in the user image.
   * Resulting effects will be applied to div containing the image returned by getResults
   * or to the container specified in constructor.
   * @returns {Promise} When promise is resolved, it will return the style
   * object to be applied if you wish to animate the effect
   * @see [getResults]{@link RevieveAR#getResults}
   * @param {String} effectName - Name of the effect to be applied.
   * @param {String[]} masks - Array of {@link RevieveAR.masks} defining the areas where
   * the effect is going to be applied.
   * @param {Float=} strength - Strength of the reduction effect.
   * See {@link RevieveAR.defaultStrength}
   * @param {String=} fillColor - Rgb, hex or name of color you want to be applied.
   * @param {Boolean=} highlight - true if you want to highlight a problem in an area
   * @param {Boolean=} highlightShape - true if you want to zoom
   * @param {Object[]} ranges - ranges configuration for wrinkles visualization.
   * (for example, face shape)
   */
  setEffectByName(
    effectName,
    masks,
    strength = this._state.defaultStrength,
    fillColor,
    highlight = false,
    highlightShape = false,
    ranges,
    shapeConfig,
  ) {
    return new Promise(async (resolve, reject) => {
      if (!this._sdk.CV.getResults()) {
        this._state.error = true;
        let message = 'RevieveAR needs an image analyzed by CV module before';
        console.error(message);
        reject(new Error(message));
        return;
      }
      if (this._state.needsReset) {
        this.reset();
        this._state.needsReset = false;
      }
      if (fillColor && this._state.whiteBalanceCorrection && this._state.whiteBalanceValue) {
        fillColor = applyWhiteBalanceToColor({ color: fillColor, whiteBalanceInfo: this._state.whiteBalanceValue });
      }
      await this.initializeImage();
      await this.drawOriginalImage();
      this.drawEffect(
        effectName,
        masks,
        strength || this._state.defaultStrength,
        fillColor,
        highlight,
        highlightShape,
        ranges,
        shapeConfig,
      )
        .then(() => {
          this._state.error = false;
          // we put the effect in the effect history object
          for (let i = 0; i < this._state.effectHistory.length; i++) {
            if (this._state.effectHistory[i].effectName === effectName) {
              this._state.effectHistory.splice(i);
              break;
            }
          }
          this._state.effectHistory.push({
            effectName,
            masks,
            strength,
            fillColor,
            highlight,
            highlightShape,
            ranges,
          });
          // we remove the canvas from the
          let canvas = this._state.layers[highlight || highlightShape ? 'highlight' : effectName];
          if (this._resultDiv) {
            for (let divChildren of this._resultDiv.children) {
              if (divChildren.id === canvas.id) {
                this._resultDiv.removeChild(divChildren);
                break;
              }
            }
          }
          if (parseFloat(strength) > 0) {
            this._resultDiv.appendChild(canvas);
          }
          // let containerObject = this.getContainer();
          // while (containerObject.firstChild) {
          //   containerObject.removeChild(containerObject.firstChild);
          // }
          // containerObject.appendChild(this._resultDiv);
          const areaToFocus = convertMaskToArea(masks);
          let animationStyle = getAnimationStyle(
            canvas,
            this._sdk.CV.getFaceArea(),
            this._state.image.ratio,
            masks && masks.length > 1 ? 'face' : areaToFocus,
            this._state.scaleFactor,
          );
          resolve(animationStyle);
        })
        .catch((error) => {
          this._error = true;
          if (error) {
            console.error(error);
          }
          console.error('Error applying effects');
          reject(error);
        });
    });
  }

  getFindingInformation(finding, masks) {
    let cvResultsForFinding = this._sdk.CV.getFindingInformation(finding);
    if (!cvResultsForFinding) return null;
    if (masks && masks.length > 0) {
      const measurements = cvResultsForFinding.measurement_locations.filter((element) =>
        masks.includes(element.description),
      );
      if (measurements.length === 0) {
        return cvResultsForFinding.measurement_locations;
      } else {
        return measurements;
      }
    }
    return cvResultsForFinding.measurement_locations;
  }

  getPolylineFindingInformation({ ranges, finding, masks }) {
    let shapesDetected = [];
    const measurements = this.getFindingInformation(finding, masks);
    measurements.forEach((measurementLocation) => {
      if (measurementLocation.visualization_data) {
        measurementLocation.visualization_data.forEach((visualizationData) => {
          let points = [];
          for (let i = 0; i < visualizationData.length; i++) {
            points.push([visualizationData.x[i], visualizationData.y[i]]);
          }
          let area = {
            color: ranges ? getRangeColor(visualizationData.mean_intensity, ranges) : null,
            params: points,
            type: 'polyline',
            intensity: visualizationData.mean_intensity,
          };
          shapesDetected.push(area);
        });
      }
    });
    return { shapes: shapesDetected };
  }

  getIndividualFindingInformation({ ranges, finding, masks, radius, lineWidth }) {
    let measurements = this.getFindingInformation(finding, masks);

    let detectedPoints = [];
    measurements.forEach((measurementLocation) => {
      if (measurementLocation.visualization_data) {
        measurementLocation.visualization_data.forEach((visualizationData) => {
          let detectedPoint = [visualizationData.x[0], visualizationData.y[0]];
          let point = {
            color: ranges ? getRangeColor(visualizationData.mean_intensity, ranges) : null,
            params: detectedPoint,
            type: 'point',
            radius,
            intensity: visualizationData.mean_intensity,
            lineWidth,
          };
          detectedPoints.push(point);
        });
      }
    });
    return { points: detectedPoints };
  }

  getCircleAreaFindingInformation({ ranges, finding, measure, masks }) {
    let detectedPoints = [];
    const reference = Math.min(this._state.image.width, this._state.image.height);
    const lineWidth = parseInt(reference * 0.005, 10);
    const measurements = this.getFindingInformation(finding, masks);
    measurements.forEach(({ AOI_radius_o: radius, AOI_x_o: x, AOI_y_o: y, value, measures }) => {
      const detectedPoint = [x, y];
      const valueDetected = measure ? measures.find(({ description }) => description === measure).value : value;
      const point = {
        color: ranges ? getRangeColor(valueDetected, ranges) : null,
        params: detectedPoint,
        type: 'point',
        radius,
        intensity: value,
        lineWidth,
      };
      detectedPoints.push(point);
    });
    return { points: detectedPoints };
  }

  async getEffectConfiguration(effectName, userMasks, highlight = false, highlightShape = false, ranges) {
    if (!effectName) {
      console.error('Effect name and masks are mandatory');
      return null;
    }
    let masks = userMasks;
    let effectNameDefined = effectName;
    if (highlight) {
      effectNameDefined = 'highlight';
    } else if (highlightShape) {
      effectNameDefined = 'highlightShape';
    }
    if (!masks && RevieveAR.effectBundles[effectNameDefined] && RevieveAR.effectBundles[effectNameDefined].masks) {
      masks = RevieveAR.effectBundles[effectNameDefined].masks[this._sdk.getApiVersion()];
    }
    switch (effectName) {
      case 'wrinklesIndividually':
        return {
          lines: this.getPolylineFindingInformation({ ranges, finding: 'wrinkles', masks }),
        };
      case 'hyperpigmentationIndividually':
        return {
          dotPoints: this.getIndividualFindingInformation({ ranges, finding: 'hyperpigmentation', masks }),
        };
      case 'frecklesIndividually':
        return {
          dotPoints: this.getIndividualFindingInformation({ ranges, finding: 'freckles', masks }),
        };
      case 'darkSpotsIndividually':
        return {
          dotPoints: this.getIndividualFindingInformation({ ranges, finding: 'dark_spots', masks }),
        };
      case 'acneIndividually': {
        const reference = Math.min(this._state.image.width, this._state.image.height);
        const radius = parseInt(reference * 0.01, 10);
        const lineWidth = parseInt(radius * 0.5, 10);
        return {
          circlePoints: this.getIndividualFindingInformation({ ranges, finding: 'acne', masks, radius, lineWidth }),
        };
      }
      case 'highlightSkinShine':
        return {
          polygons: this.getPolylineFindingInformation({ ranges, finding: 'Skin shine' }),
        };
      case 'melasmaIndividually':
        return {
          polygons: this.getPolylineFindingInformation({ ranges, finding: 'melasma', masks }),
        };
      case 'unevenSkinToneIndividually':
        return {
          polygons: this.getPolylineFindingInformation({ ranges, finding: 'uneven_skin_tone', masks }),
        };
      case 'rednessIndividually':
        return {
          polygons: this.getPolylineFindingInformation({ ranges, finding: 'redness', masks }),
        };
      case 'textureIndividually':
        return {
          polygons: this.getPolylineFindingInformation({ ranges, finding: 'texture', masks }),
        };
      case 'poreDilationIndividually':
        return {
          polygons: this.getPolylineFindingInformation({ ranges, finding: 'pore_dilation', masks }),
        };
      case 'skinSaggingIndividually':
        return {
          polygons: this.getPolylineFindingInformation({ ranges, finding: 'skin_sagging' }),
        };
      case 'nasolabialFoldsIndividually':
        return {
          lines: this.getPolylineFindingInformation({ ranges, finding: 'nasolabial_folds' }),
        };
      case 'marionetteLinesIndividually':
        return {
          lines: this.getPolylineFindingInformation({ ranges, finding: 'marionette_lines' }),
        };
      case 'underEyeLinesIndividually':
        return {
          lines: this.getPolylineFindingInformation({ ranges, finding: 'under_eye_lines' }),
        };
      case 'foreheadLinesIndividually':
        return {
          lines: this.getPolylineFindingInformation({ ranges, finding: 'forehead_lines' }),
        };
      case 'wrinklesForeheadEyesIndividually':
        return {
          lines: this.getPolylineFindingInformation({ ranges, finding: 'wrinkles_forehead_eyes' }),
        };
      case 'hyperpigmentationAreaValues': {
        return {
          circlePoints: this.getCircleAreaFindingInformation({
            ranges,
            finding: 'hyperpigmentation',
            masks,
          }),
        };
      }
      case 'melasmaAreaValues': {
        return {
          circlePoints: this.getCircleAreaFindingInformation({
            ranges,
            finding: 'melasma',
            masks,
          }),
        };
      }
      case 'frecklesAreaValues': {
        return {
          circlePoints: this.getCircleAreaFindingInformation({
            ranges,
            finding: 'freckles',
            masks,
          }),
        };
      }
      case 'darkSpotsAreaValues': {
        return {
          circlePoints: this.getCircleAreaFindingInformation({
            ranges,
            finding: 'dark_spots',
            masks,
          }),
        };
      }
      case 'wrinklesAreaValues': {
        return {
          circlePoints: this.getCircleAreaFindingInformation({
            ranges,
            finding: 'wrinkles',
            masks,
          }),
        };
      }
      case 'rednessAreaValues': {
        return {
          circlePoints: this.getCircleAreaFindingInformation({
            ranges,
            finding: 'redness',
            masks,
          }),
        };
      }
      case 'textureAreaValues': {
        return {
          circlePoints: this.getCircleAreaFindingInformation({
            ranges,
            finding: 'texture',
            masks,
          }),
        };
      }
      case 'acneAreaValues': {
        return {
          circlePoints: this.getCircleAreaFindingInformation({
            ranges,
            finding: 'acne',
            masks,
          }),
        };
      }
      case 'smoothnessAreaValues': {
        return {
          circlePoints: this.getCircleAreaFindingInformation({
            ranges,
            finding: 'smoothness',
            masks,
          }),
        };
      }
      case 'unevenSkinToneAreaValues': {
        return {
          circlePoints: this.getCircleAreaFindingInformation({
            ranges,
            finding: 'uneven_skin_tone',
            masks,
          }),
        };
      }
      case 'eyebagsAreaValues': {
        return {
          circlePoints: this.getCircleAreaFindingInformation({
            ranges,
            finding: 'eyes',
            measure: 'eyebags',
            masks,
          }),
        };
      }
      case 'darkcirclesAreaValues': {
        return {
          circlePoints: this.getCircleAreaFindingInformation({
            ranges,
            finding: 'eyes',
            measure: 'dark circles',
            masks,
          }),
        };
      }
      default:
        if (masks) {
          let masksObjects = [];
          for (let mask of masks) {
            for (let allMask of this._sdk.CV.getMasks()) {
              if (allMask.name === mask) {
                masksObjects.push(allMask);
              }
            }
          }
          masksObjects = this.findBestFitMasksForEffect(effectName, masksObjects);
          masksObjects = await getMasksShape({
            masks: masksObjects,
            width: this._state.image.width,
            height: this._state.image.height,
          });

          let individualFindingsInfo = {};
          if (
            RevieveAR.effectActions[effectNameDefined] &&
            RevieveAR.effectActions[effectNameDefined].needsIndividualFindingsInfo
          ) {
            individualFindingsInfo = {
              individualFindingsInfo: this.getIndividualFindingInformation({
                finding: RevieveAR.effectActions[effectNameDefined].individualFindingsInfo,
                masks,
              }),
            };
          }
          return {
            effects: RevieveAR.effectBundles[effectNameDefined].effects,
            masks: masksObjects,
            ...individualFindingsInfo,
          };
        } else {
          return null;
        }
    }
  }

  drawEffect(userEffect, masks, intensity, pFillColor, highlight = false, highlightShape = false, ranges, shapeConfig) {
    return new Promise(async (resolve, reject) => {
      if (parseFloat(intensity) <= 0) {
        resolve();
      }
      let fillColor = pFillColor;
      if (highlight) {
        // for highlight we user pFillColor to fill the outside area of the shape
        fillColor = 'rgb(255,255,255)';
      }
      let effectConfiguration = await this.getEffectConfiguration(userEffect, masks, highlight, highlightShape, ranges);
      if (!effectConfiguration) {
        reject();
        return;
      }
      let masksPending = 0;
      let firstMask = true;
      let userEffectDefined = userEffect;
      if (highlight || highlightShape) {
        userEffectDefined = 'highlight';
      }
      if (effectConfiguration.lines) {
        let canvas = this._state.layers[userEffectDefined];
        if (!canvas) {
          canvas = createNewCanvas(userEffectDefined, this._state.image.width, this._state.image.height);
          this._state.layers[userEffectDefined] = canvas;
        }
        let context = canvas.getContext('2d');
        context.clearRect(0, 0, this._state.image.width, this._state.image.height);
        const reference = Math.min(this._state.image.width, this._state.image.height);
        const wrinkleLineWidth = parseInt(reference * 0.005, 10);
        effectConfiguration.lines.shapes.forEach((wrinkle) => {
          drawLine(context, wrinkle.params, wrinkle.color, false, wrinkleLineWidth);
        });
        canvas.style.transform = 'scale(' + this._state.image.ratio + ')';
        resolve();
      } else if (effectConfiguration.dotPoints) {
        let canvas = this._state.layers[userEffectDefined];
        if (!canvas) {
          canvas = createNewCanvas(userEffectDefined, this._state.image.width, this._state.image.height);
          this._state.layers[userEffectDefined] = canvas;
        }
        let context = canvas.getContext('2d');
        context.clearRect(0, 0, this._state.image.width, this._state.image.height);
        const reference = Math.min(this._state.image.width, this._state.image.height);
        const pointRadius = parseInt(reference * 0.005, 10);
        effectConfiguration.dotPoints.points.forEach((point) => {
          drawCircle(canvas, point.params[0], point.params[1], pointRadius, point.color, null, false);
        });
        canvas.style.transform = 'scale(' + this._state.image.ratio + ')';
        resolve();
      } else if (effectConfiguration.circlePoints) {
        let canvas = this._state.layers[userEffectDefined];
        if (!canvas) {
          canvas = createNewCanvas(userEffectDefined, this._state.image.width, this._state.image.height);
          this._state.layers[userEffectDefined] = canvas;
        }
        let context = canvas.getContext('2d');
        context.clearRect(0, 0, this._state.image.width, this._state.image.height);

        effectConfiguration.circlePoints.points.forEach((point) => {
          drawCircle(
            canvas,
            point.params[0],
            point.params[1],
            point.radius,
            'rgba(0,0,0,0)',
            point.color,
            false,
            point.lineWidth,
          );
        });
        canvas.style.transform = 'scale(' + this._state.image.ratio + ')';
        resolve();
      } else if (effectConfiguration.polygons) {
        let canvas = this._state.layers[userEffectDefined];
        if (!canvas) {
          canvas = createNewCanvas(userEffectDefined, this._state.image.width, this._state.image.height);
          this._state.layers[userEffectDefined] = canvas;
        }
        let context = canvas.getContext('2d');
        context.clearRect(0, 0, this._state.image.width, this._state.image.height);
        effectConfiguration.polygons.shapes.forEach((shineArea) => {
          drawPolygon(context, shineArea.params, null, { fill: { color: shineArea.color } });
        });
        canvas.style.transform = 'scale(' + this._state.image.ratio + ')';
        resolve();
      } else if (effectConfiguration.masks) {
        if (shapeConfig) {
          let canvas = this._state.layers[userEffectDefined];
          if (!canvas) {
            canvas = createNewCanvas(userEffectDefined, this._state.image.width, this._state.image.height);
            this._state.layers[userEffectDefined] = canvas;
          }
          let context = canvas.getContext('2d');
          context.clearRect(0, 0, this._state.image.width, this._state.image.height);
          effectConfiguration.masks.forEach((mask) => {
            drawPolygon(context, mask.contour, null, shapeConfig);
          });
          canvas.style.transform = 'scale(' + this._state.image.ratio + ')';
          resolve();
        } else {
          for (let mask of effectConfiguration.masks) {
            let canvas = createNewCanvas(userEffectDefined, this._state.image.width, this._state.image.height);
            for (let effect of effectConfiguration.effects) {
              masksPending++;
              draw(
                canvas,
                mask,
                effect.needsSkinTexture ? this._sdk.CV.getImage() : null,
                effect.needsSkintoneColor ? this._state.skintoneColor : fillColor,
                effect.useStroke,
                effect.needsIndividualFindingsInfo ? effectConfiguration.individualFindingsInfo.points : null,
              )
                .then(() => {
                  applyEffectsInCanvas(
                    effect,
                    intensity,
                    canvas,
                    this._state.image.ratio,
                    this._state.image.width,
                    this._state.image.height,
                  );
                  if (!this._state.layers[userEffectDefined]) {
                    this._state.layers[userEffectDefined] = {};
                  }
                  if (firstMask) {
                    this._state.layers[userEffectDefined] = canvas;
                    firstMask = false;
                  } else {
                    let originalCanvas = this._state.layers[userEffectDefined];
                    let originalCanvasContext = originalCanvas.getContext('2d');
                    originalCanvasContext.drawImage(canvas, 0, 0);
                    canvas.remove();
                  }
                  masksPending--;
                  if (masksPending === 0) {
                    if (highlight) {
                      highlightArea(this._state.layers[userEffectDefined], pFillColor);
                    }
                    resolve();
                  }
                })
                .catch((error) => {
                  console.error(error);
                  reject();
                });
            }
          }
        }
      } else {
        reject();
      }
    });
  }

  findBestFitMasksForEffect(effectName, masks) {
    let masksFit = masks.filter((mask) => levenshteinDistance(mask.section, effectName) <= effectName.length * 0.5);
    if (!masksFit || masksFit.length === 0) {
      // We aren't able to find a best fit masks.
      masksFit = masks;
    }
    return masksFit;
  }

  /**
   * Method to reset results and delete all the effects applied before.
   */
  reset(includeOriginalImage = true) {
    clearCachedMasks();
    const container = this.getContainer();
    while (container.firstChild) {
      container.removeChild(container.firstChild);
    }
    if (!this._state.externalContainer) {
      this._state.container = null;
    }
    if (this._resultDiv) {
      while (this._resultDiv.firstChild) {
        this._resultDiv.removeChild(this._resultDiv.firstChild);
      }
      this._resultDiv = null;
    }
    this._state.image = {};
    this._state.layers = {};
    this._state.effectHistory = [];
    if (includeOriginalImage) this.drawOriginalImage();
  }

  /**
   * Test method that add to the root of the HTML document two objects: the original image
   * and the resulting div with all the effects applied
   */
  testImages() {
    if (!document.getElementById('originalImage')) {
      let originalImage = document.createElement('img');
      originalImage.id = 'originalImage';
      originalImage.src = this._sdk.CV.getImage();
      document.body.appendChild(originalImage);
    }
    if (this._resultDiv) {
      document.body.appendChild(this._resultDiv);
    }
  }

  _setSkintoneColor(skintoneColor) {
    this._state.skintoneColor = skintoneColor;
  }

  /**
   * See the results of the manipulations.
   * @returns {HTMLDivElement} After image - Div containing original image with added manipulations
   */
  getResultsDiv() {
    return this._resultDiv;
  }

  /**
   * Gets the container object where effects are going to be rendered. If no container is defined, it will use a container based on the image size.
   */
  getContainer() {
    if (!this._state.container) {
      const container = document.createElement('div');
      container.setAttribute('id', 'revieveContainer');
      container.setAttribute('width', this._state.image.width);
      container.setAttribute('height', this._state.image.height);
      this._state.container = container;
    }
    return this._state.container;
  }

  /**
   * Get an image with all the active effects applied
   * @returns {Base64} Returns an JPG image with all the effects and highlight you have applied with methods.
   */
  async getResultsImage() {
    return null;
  }
}

function createNewCanvas(id, width, height) {
  let canvas = document.createElement('canvas');
  canvas.setAttribute('id', id);
  canvas.setAttribute('width', width);
  canvas.setAttribute('height', height);
  canvas.style.position = 'absolute';
  canvas.style.top = '0px';
  canvas.style.left = '0px';
  canvas.style.transformOrigin = 'top left';
  let acontext = canvas.getContext('2d');
  acontext.clearRect(0, 0, width, height);
  return canvas;
}

function applyEffectsInCanvas(effects, intensity, canvas, ratio, width, height) {
  if (!effects) {
    return;
  }
  let opacityApplied = false;
  for (let effect in effects) {
    if (effect === 'opacity') {
      let finalOpacity = parseFloat(intensity) * parseFloat(effects[effect]);
      canvas.style.opacity = finalOpacity;
      opacityApplied = true;
      canvas.style.opacity = finalOpacity;
    } else if (effect === 'filter') {
      if (effects.blurResolutionDependent) {
        const lowerResolution = Math.min(width, height);
        let newFilterValue;
        if (effects.id === 'highlight') {
          newFilterValue = effects[effect].replace(
            'blur(' + effects.blurResolutionDependent + 'px)',
            'blur(' + parseInt(lowerResolution * 0.004, 10) + 'px)',
          );
        } else {
          newFilterValue = effects[effect].replace(
            'blur(' + effects.blurResolutionDependent + 'px)',
            'blur(' +
              parseInt(
                effects.blurResolutionDependent * lowerResolution * (effects.blurResolutionDependentFactor || 0.001),
                10,
              ) +
              'px)',
          );
        }
        canvas.style[effect] = newFilterValue;
      } else {
        canvas.style[effect] = effects[effect];
      }
    }
  }
  if (ratio) {
    canvas.style.transform = 'scale(' + ratio + ')';
  }
  if (!opacityApplied && intensity) {
    canvas.style.opacity = parseFloat(intensity);
  }
}

// Product visualization category must be lower case and without spaces
let productVisualization = [
  {
    category: 'blush',
    method: 'applyBlush',
    colorExpression: 'color_hex_',
    strength: 0.7,
  },
  {
    category: 'lipstick',
    method: 'applyLipstick',
    colorExpression: 'color_hex_',
    strength: 0.45,
  },
  {
    category: 'foundation',
    method: 'applyFoundation',
    colorExpression: 'color_hex_',
    strength: 0.4,
  },
  {
    category: 'eyeliner',
    method: 'applyEyeliner',
    colorExpression: 'color_hex_',
    strength: 0.5,
  },
  {
    category: 'lipliner',
    method: 'applyLipliner',
    colorExpression: 'color_hex_',
    strength: 0.5,
  },
  {
    category: 'eyeshadow',
    method: 'applyEyeshadow',
    colorExpression: 'color_hex_',
    strength: 0.4,
  },
];

let eyeColorMapping = {
  brown: '#573823',
  blue: '#176F9C',
  green: '#5CAD82',
  hazel: '#979C17',
};

let skinUndertoneMapping = {
  cool: '#F0E4E4',
  neutral: '#EECDBA',
  warm: '#D6B1A1',
};

RevieveAR.makeupMeasures = makeupMeasures;
RevieveAR.defaultStrength = defaultStrength;

RevieveAR.masks = {
  2: {
    SKIN: 'Skin',
    FOREHEAD: 'forehead',
    FOREHEAD_WORRY_LINES: 'forehead worry lines',
    BETWEEN_BROWS_FROWN_LINES: 'between-brows frown lines',
    NOSELINE_BUNNY_LINES: 'noseline bunny lines',
    // eslint-disable-next-line quotes
    LEFT_SIDE_EYE_CROWS_FEET: "left side eye crow's feet",
    // eslint-disable-next-line quotes
    RIGHT_SIDE_EYE_CROWS_FEET: "right side eye crow's feet",
    LEFT_SIDE_TEARLINE: 'left side tearline',
    RIGHT_SIDE_TEARLINE: 'right side tearline',
    LEFT_EYEBAG: 'left eye',
    RIGHT_EYEBAG: 'right eye',
    EYEBROWS: 'Eyebrows',
    LEFT_CHEEK: 'left cheek',
    RIGHT_CHEEK: 'right cheek',
    LIPS: 'Lips',
  },
  3: {
    skincare: {
      SKIN: 'Skin',
      FOREHEAD: 'forehead',
      FOREHEAD_WORRY_LINES: 'forehead worry lines',
      BETWEEN_BROWS_FROWN_LINES: 'between-brows frown lines',
      LEFT_SIDE_EYE: 'left side around the eye',
      RIGHT_SIDE_EYE: 'right side around the eye',
      // eslint-disable-next-line quotes
      LEFT_SIDE_EYE_CROWS_FEET: "left side eye crow's feet",
      // eslint-disable-next-line quotes
      RIGHT_SIDE_EYE_CROWS_FEET: "right side eye crow's feet",
      LEFT_EYEBAG: 'left eye',
      RIGHT_EYEBAG: 'right eye',
      LEFT_UNDER_EYE: 'left under-the-eye region',
      RIGHT_UNDER_EYE: 'right under-the-eye region',
      LEFT_SIDE_TEARLINE: 'left side tearline',
      RIGHT_SIDE_TEARLINE: 'right side tearline',
      LEFT_CHEEK: 'left cheek',
      RIGHT_CHEEK: 'right cheek',
      NOSE: 'nose',
      NOSELINE_BUNNY_LINES: 'noseline bunny lines',
      LEFT_SIDE_MOUTH: 'left side around mouth',
      RIGHT_SIDE_MOUTH: 'right side around mouth',
      CHIN: 'chin',
      LEFT_SIDE_NASOLABIAL: 'left side nasolabial line',
      RIGHT_SIDE_NASOLABIAL: 'right side nasolabial line',
      LEFT_SIDE_MARIONETTE: 'left side marionette line',
      RIGHT_SIDE_MARIONETTE: 'right side marionette line',
      VERTICAL_LIP_LINES: 'vertical lip lines',
      RIGHT_LOWER_FACE: 'right lower face',
      LEFT_LOWER_FACE: 'left lower face',
    },
    makeup: {
      SKIN: 'Skin',
      EYEBROWS: 'Eyebrows',
      LEFT_CHEEK: 'Left cheek',
      RIGHT_CHEEK: 'Right cheek',
      NOSE: 'Nose',
      LIPS: 'Lips',
      EYELINER: 'Eyelines',
      LIPLINER: 'Liplines',
      EYESHADOW: 'Eyeshadow',
    },
  },
};

RevieveAR.effectActions = {
  reduceRedness: {
    id: 'reduceRedness',
    filter: 'blur(15px) saturate(85%) brightness(110%)',
    mixBlendMode: 'screen',
    opacity: 0.2,
    needsSkintoneColor: true,
    needsSkinTexture: false,
    cleanEyesArea: true,
  },
  reduceHyperpigmentation: {
    id: 'reduceHyperpigmentation',
    filter: 'blur(5px) saturate(75%) brightness(110%)',
    mixBlendMode: 'screen',
    opacity: 0.5,
    needsSkinTexture: true,
    cleanEyesArea: true,
    needsSkintoneColor: false,
    needsIndividualFindingsInfo: true,
    individualFindingsInfo: 'hyperpigmentation',
    blurResolutionDependent: 5,
    blurResolutionDependentFactor: 0.002,
  },
  reduceWrinkles: {
    id: 'reduceWrinkles',
    filter: 'blur(7px) saturate(85%) brightness(110%)',
    opacity: 0.4,
    needsSkinTexture: true,
    cleanEyesArea: true,
  },
  brightenSkin: {
    id: 'brightenSkin',
    filter: 'blur(15px) saturate(85%) brightness(110%)',
    mixBlendMode: 'screen',
    opacity: 0.2,
    needsSkintoneColor: true,
    needsSkinTexture: false,
    cleanEyesArea: true,
  },
  reduceEyebags: {
    id: 'reduceEyebags',
    filter: 'blur(5px) brightness(105%)',
    opacity: 0.75,
    needsSkinTexture: true,
  },
  reduceDarkcircles: {
    id: 'reduceDarkcircles',
    filter: 'blur(15px) saturate(85%) brightness(125%)',
    mixBlendMode: 'screen',
    opacity: 0.15,
    needsSkintoneColor: true,
    needsSkinTexture: false,
  },
  colorizeHard: {
    id: 'colorizeHard',
    filter: 'blur(3px)',
    opacity: 0.9,
    needsSkinTexture: false,
    blurResolutionDependent: 3,
  },
  colorizeMedium: {
    id: 'colorizeMedium',
    filter: 'blur(3px)',
    opacity: 0.5,
    needsSkinTexture: false,
    blurResolutionDependent: 3,
  },
  colorizeSoft: {
    id: 'colorizeSoft',
    filter: 'blur(40px)',
    opacity: 0.35,
    needsSkinTexture: false,
    blurResolutionDependent: 40,
  },
  foundation: {
    id: 'foundation',
    filter: 'blur(20px) brightness(110%)',
    opacity: 0.2,
    needsSkinTexture: false,
    blurResolutionDependent: 20,
  },
  highlight: {
    id: 'highlight',
    filter: 'blur(10px)',
    opacity: 0.5,
    // filter: '',
    // opacity: 1,
    needsSkinTexture: false,
    blurResolutionDependent: 10,
  },
  highlightShape: {
    id: 'highlightShape',
    filter: 'blur(5px)',
    opacity: 0.5,
    needsSkinTexture: false,
    useStroke: true,
  },
};
RevieveAR.effectBundles = {
  reduceEyebags: {
    masks: {
      2: [RevieveAR.masks[2].LEFT_EYEBAG, RevieveAR.masks[2].RIGHT_EYEBAG],
      3: [RevieveAR.masks[3].skincare.LEFT_EYEBAG, RevieveAR.masks[3].skincare.RIGHT_EYEBAG],
    },
    effects: [RevieveAR.effectActions.reduceEyebags],
    description: 'Reduce Eyebags',
    parameters: [
      {
        name: 'strength',
        type: 'number',
      },
    ],
    type: 'Skincare',
  },
  reduceCrowsFeet: {
    masks: {
      2: [RevieveAR.masks[2].LEFT_SIDE_EYE_CROWS_FEET, RevieveAR.masks[2].RIGHT_SIDE_EYE_CROWS_FEET],
      3: [RevieveAR.masks[3].skincare.LEFT_SIDE_EYE_CROWS_FEET, RevieveAR.masks[3].skincare.RIGHT_SIDE_EYE_CROWS_FEET],
    },
    effects: [RevieveAR.effectActions.reduceWrinkles],
    description: 'Reduce Crows Feet',
    parameters: [
      {
        name: 'strength',
        type: 'number',
      },
    ],
    type: 'Skincare',
  },
  reduceDarkcircles: {
    masks: {
      2: [RevieveAR.masks[2].LEFT_EYEBAG, RevieveAR.masks[2].RIGHT_EYEBAG],
      3: [RevieveAR.masks[3].skincare.LEFT_EYEBAG, RevieveAR.masks[3].skincare.RIGHT_EYEBAG],
    },
    effects: [RevieveAR.effectActions.reduceDarkcircles],
    description: 'Reduce Darkcircles',
    parameters: [
      {
        name: 'strength',
        type: 'number',
      },
    ],
    type: 'Skincare',
  },
  reduceRedness: {
    reactParameterType: 'object',
    effects: [RevieveAR.effectActions.reduceRedness],
    description: 'Reduce Redness',
    parameters: [
      {
        name: 'strength',
        type: 'number',
      },
      {
        name: 'masks',
        type: 'array',
      },
    ],
    type: 'Skincare',
  },
  reduceHyperpigmentation: {
    reactParameterType: 'object',
    effects: [RevieveAR.effectActions.reduceHyperpigmentation],
    description: 'Reduce Hyperpigmentation',
    parameters: [
      {
        name: 'strength',
        type: 'number',
      },
      {
        name: 'masks',
        type: 'array',
      },
    ],
    type: 'Skincare',
  },
  reduceWrinkles: {
    reactParameterType: 'object',
    effects: [RevieveAR.effectActions.reduceWrinkles],
    description: 'Reduce Wrinkles',
    parameters: [
      {
        name: 'strength',
        type: 'number',
      },
      {
        name: 'masks',
        type: 'array',
      },
    ],
    type: 'Skincare',
  },
  brightenSkin: {
    reactParameterType: 'object',
    effects: [RevieveAR.effectActions.brightenSkin],
    description: 'Brighten Skin',
    parameters: [
      {
        name: 'strength',
        type: 'number',
      },
      {
        name: 'masks',
        type: 'array',
      },
    ],
    type: 'Skincare',
  },
  applyLipstick: {
    reactParameterType: 'object',
    masks: {
      2: [RevieveAR.masks[2].LIPS],
      3: [RevieveAR.masks[3].makeup.LIPS],
    },
    effects: [RevieveAR.effectActions.colorizeMedium],
    description: 'Apply Lipstick',
    parameters: [
      {
        name: 'strength',
        type: 'number',
      },
      {
        name: 'color',
        type: 'string',
      },
    ],
    type: 'Makeup',
  },
  applyBlush: {
    reactParameterType: 'object',
    masks: {
      2: [RevieveAR.masks[2].LEFT_CHEEK, RevieveAR.masks[2].RIGHT_CHEEK],
      3: [RevieveAR.masks[3].makeup.LEFT_CHEEK, RevieveAR.masks[3].makeup.RIGHT_CHEEK],
    },
    effects: [RevieveAR.effectActions.colorizeSoft],
    description: 'Apply Blush',
    parameters: [
      {
        name: 'strength',
        type: 'number',
      },
      {
        name: 'color',
        type: 'string',
      },
    ],
    type: 'Makeup',
  },
  applyEyeliner: {
    reactParameterType: 'object',
    masks: {
      3: [RevieveAR.masks[3].makeup.EYELINER],
    },
    effects: [RevieveAR.effectActions.colorizeHard],
    description: 'Apply Eyeliner',
    parameters: [
      {
        name: 'strength',
        type: 'number',
      },
      {
        name: 'color',
        type: 'string',
      },
    ],
    type: 'Makeup',
  },
  applyLipliner: {
    reactParameterType: 'object',
    masks: {
      3: [RevieveAR.masks[3].makeup.LIPLINER],
    },
    effects: [RevieveAR.effectActions.colorizeHard],
    description: 'Apply Lipliner',
    parameters: [
      {
        name: 'strength',
        type: 'number',
      },
      {
        name: 'color',
        type: 'string',
      },
    ],
    type: 'Makeup',
  },
  applyEyeshadow: {
    reactParameterType: 'object',
    masks: {
      3: [RevieveAR.masks[3].makeup.EYESHADOW],
    },
    effects: [RevieveAR.effectActions.colorizeMedium],
    description: 'Apply Eyeshadow',
    parameters: [
      {
        name: 'strength',
        type: 'number',
      },
      {
        name: 'color',
        type: 'string',
      },
    ],
    type: 'Makeup',
  },
  applyFoundation: {
    reactParameterType: 'number',
    masks: {
      2: [RevieveAR.masks[2].SKIN],
      3: [RevieveAR.masks[3].makeup.SKIN],
    },
    effects: [RevieveAR.effectActions.foundation],
    description: 'Apply Foundation',
    parameters: [
      {
        name: 'strength',
        type: 'number',
      },
      {
        name: 'color',
        type: 'string',
      },
    ],
    type: 'Makeup',
  },
  highlight: {
    reactParameterType: 'object',
    effects: [RevieveAR.effectActions.highlight],
    description: 'Highlight',
    parameters: [
      {
        name: 'masks',
        type: 'array',
      },
      {
        name: 'color',
        type: 'string',
      },
    ],
    type: 'Skincare',
  },
  highlightShape: {
    reactParameterType: 'object',
    effects: [RevieveAR.effectActions.highlightShape],
    description: 'Highlight',
    parameters: [
      {
        name: 'masks',
        type: 'array',
      },
      {
        name: 'color',
        type: 'string',
      },
    ],
    type: 'Skincare / Makeup',
  },
};

export default RevieveAR;
